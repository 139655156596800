<template>
  <div id="title-uedit">
    <Editor
      style="
        height: 50px;
        overflow-y: hidden;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        line-height: 50px;
      "
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
    />
    <Toolbar :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  components: { Editor, Toolbar },
  props: ["title"],
  data() {
    return {
      editor: null,
      html: this.title,
      toolbarConfig: {
        toolbarKeys: ["bold", "italic", "underline", "color"],
      },
      editorConfig: {
        placeholder: "请输入标题...",
        scroll: false, // 是否滚动  默认true
        hoverbarKeys: [],
      },
      mode: "simple", // or 'simple'
    };
  },
  watch: {
    title() {
      //父组件值变化->>子组件也变化
      this.value = this.title;
      this.$forceUpdate();
    },
    html(newvalue) {
      //上一步的子组件(data)变化 提交给父组件事件 进行页面数据的更新
      this.$emit("show", newvalue);
    },
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
  },
  mounted() {},
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>

<style lang="less" scoped>
#title-uedit {
  width: 100%;
  height: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
}
</style>