import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as echarts from 'echarts';
import qs from 'qs'
import moment from 'moment';
import cookies from 'js-cookie'

// import Vcode from "vue-puzzle-vcode";

// 一键复制
import VueClipBoard from 'vue-clipboard2'

Vue.use(VueClipBoard)


// import {
//     encrypt,
//     decrypt
// } from './utils/sm4'


import {
    encrypt,
    decrypt
} from './utils/AesUtils'

import "./assets/css/base.css"

Vue.config.productionTip = false

Vue.use(ElementUI);
Vue.use(VueAxios, axios);

import request from "./utils/request.js"
import formatTime from "./utils/formatTime.js"

import * as formatNum from "./utils/formatMathDecimal"
import * as baseData from "./utils/base"

Vue.prototype.$request = request
Vue.prototype.$formatTime = formatTime
Vue.prototype.$echarts = echarts
Vue.prototype.$qs = qs
Vue.prototype.$cookies = cookies
Vue.prototype.$formatNum = formatNum
Vue.prototype.$base = baseData


import {
    Message,
    Loading

} from 'element-ui'




Vue.prototype.$baseurl = 'http://soft-ats.com/dynamic'
// Vue.prototype.$baseurl = 'http://192.168.0.109:8083/dynamic' // mei





Vue.prototype.$getViewportSize = function () {
    // 获取当前屏幕尺寸
    return {
        width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    };
};

Vue.prototype.$getViewportSize = function () {
    // 获取当前屏幕尺寸
    return {
        width: window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth,
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    };
};

sessionStorage.setItem("returnVariable", Vue.prototype.$baseurl)

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer);
Viewer.setDefaults({
    Options: {
        "inline": true,
        "button": true,
        "navbar": true,
        "title": false,
        "toolbar": true,
        "tooltip": true,
        "movable": true,
        "zoomable": true,
        "rotatable": true,
        "scalable": true,
        "transition": true,
        "fullscreen": true,
        "keyboard": true,
        "url": "data-source"
    }
});

let loading = null

//开始 加载loading
let startLoading = () => {
    loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(255, 255, 255, 0.2)'
    })
}
//结束 取消loading加载
let endLoading = () => {
    loading.close()
}


let needLoadingRequestCount = 0 //声明一个变量

let showFullScreenLoading = () => {
    if (needLoadingRequestCount === 0) { //当等于0时证明第一次请求 这时开启loading
        startLoading()
    }
    needLoadingRequestCount++ //全局变量值++
}

let tryHideFullScreenLoading = () => {
    if (needLoadingRequestCount <= 0) return //小于等于0 证明没有开启loading 此时return
    needLoadingRequestCount-- //正常响应后 全局变量 --
    if (needLoadingRequestCount === 0) { //等于0 时证明全部加载完毕 此时结束loading 加载
        endLoading()
    }

}
// 添加请求拦截器，在请求头中加token
axios.interceptors.request.use(
    async config => {
        config.headers["token"] = localStorage.getItem("token");
        config.headers["userId"] = localStorage.getItem("userId"); // 让每个请求携带自定义token 请根据实际情况自行修改
        // //console.log("进入请求拦截器", config)
        // if (config.url.indexOf("/user/loginOut") < 0) {
        // if (config.url.indexOf("/user/getUserForLogin") < 0) {
        if (config.url.indexOf("/user/loginOut") < 0 || config.url.indexOf("/user/getUserForLogin") < 0) {
            let temp = JSON.parse(config.data)
            temp.userId = localStorage.getItem("userId");
            config.data = JSON.stringify(temp);
        }

        showFullScreenLoading()
        //数据加密

        // config.data = encrypt(config.data)
        // //console.log("加密后数据", config.data)
        return config;
    },
    error => {
        // //console.log("请求拦截器中报错")
        return Promise.reject(error);
    }
);


axios.interceptors.response.use(
    response => {
        //关闭loading加载
        // //console.log("进入响应拦截器",response)
        // 数据解密
        // response.data = JSON.parse(decrypt(response.data))
        // //console.log("respons解密数据",response.data);
        tryHideFullScreenLoading()
        return Promise.resolve(response)
    },
    error => {
        tryHideFullScreenLoading()
        console.log("请求拦截器错误数据", error);
        if (400 === error.response.status || 401 === error.response.status) {
            loginOut()
            this.$message({
                message: "登录过期!",
                type: "success",
            });
        } else if (409 === error.response.status) {
            loginOut()
            // return Promise.reject(error);
            // window.open("http://iscsso.ah.sgcc.com.cn/isc_sso/login?service=" + "http://20.50.18.47:20225/login/", "_self");
        }
        else if (302 === error.response.status) {
            loginOut()
        } else {
            return Promise.reject(error);
        }


    }
);

function returnVariable() {
    return this.$baseurl
}

function loginOut() {
    localStorage.removeItem("userId");
    localStorage.removeItem("userName");
    localStorage.removeItem("token");
    router.push("/")
}

new Vue({
    // store,
    router,
    render: h => h(App)
}).$mount('#app')
