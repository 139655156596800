<template>
  <div id="docInfo">
    <el-dialog
      :title="currentDialogData.title"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        class="itemOption"
        v-for="(item, index) in currentDialogData.listData"
        :key="index"
      >
        <div class="leftArea" v-if="index < 1">{{ item.name }}:</div>
        <!-- <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img  style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div> -->
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select
            v-model="item.value"
            :placeholder="'请选择' + item.name"
            filterable
          >
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="itemOption" v-if="currentDialogData.listData[0].value == '2'">
        <div class="leftArea">{{ currentDialogData.listData[1].name }}:</div>
        <div class="rightArea">
          <el-date-picker
            v-model="currentDialogData.listData[1].value"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureRelease()">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <textarea id="codeArea" ref="textarea"></textarea>
    <div class="optionArea">
      <div class="itemOption" v-for="(item, index) in listData" :key="index">
        <div class="leftArea">{{ item.name }}:</div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input v-model="item.value" placeholder="请输入内容"></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select v-model="item.value" placeholder="请选择" filterable>
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="rightArea" v-if="item.type == 'textarea'">
          <el-input
            type="textarea"
            autosize
            :rows="2"
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
      </div>
    </div>
    <div class="btnArea">
      <div
        class="itemBtn"
        v-for="(item, index) in btnData"
        @click="btnClickMethods(item)"
        :key="index"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
// 引入全局实例
import _CodeMirror from "codemirror";
import { js_beautify, css_beautify, html_beautify } from "js-beautify";
// 核心样式
import "codemirror/lib/codemirror.css";
// 引入主题后还需要在 options 中指定主题才会生效
import "codemirror/theme/cobalt.css";

// 需要引入具体的语法高亮库才会有对应的语法高亮效果
// codemirror 官方其实支持通过 /addon/mode/loadmode.js 和 /mode/meta.js 来实现动态加载对应语法高亮库
// 但 vue 貌似没有无法在实例初始化后再动态加载对应 JS ，所以此处才把对应的 JS 提前引入
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/css/css.js";
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/clike/clike.js";
import "codemirror/mode/markdown/markdown.js";
import "codemirror/mode/python/python.js";
import "codemirror/mode/r/r.js";
import "codemirror/mode/shell/shell.js";
import "codemirror/mode/sql/sql.js";
import "codemirror/mode/swift/swift.js";
import "codemirror/mode/vue/vue.js";
import "codemirror/addon/display/autorefresh";
import "codemirror/addon/selection/active-line.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon//lint/lint.css";
import "codemirror/addon/lint/lint.js";
// import style_html from "../utils/htmlformat.js";
import moment from "moment";
// 尝试获取全局实例
const CodeMirror = window.CodeMirror || _CodeMirror;

export default {
  name: "in-coder",
  props: {
    // 外部传入的内容，用于实现双向绑定
    value: String,
    // 外部传入的语法类型
    language: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      // dialogData
      dialogVisible: false,
      currentDialogData: {
        title: "发布模式",
        listData: [
          {
            type: "select",
            name: "发布模式",
            value: "1",
            valueList: [
              {
                id: "1",
                name: "直接发布",
              },
              {
                id: "2",
                name: "定时发布",
              },
            ],
          },
          {
            type: "dateTime",
            name: "发布时间",
            value: "",
          },
        ],
      },
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      // 内部真实的内容
      code: "",
      // 默认的语法类型
      mode: "html",
      // 编辑器实例
      coder: null,
      // 默认配置
      options: {
        // 缩进格式
        tabSize: 2,
        // 主题，对应主题库 JS 需要提前引入
        // theme: "cobalt",
        // 显示行号
        lineNumbers: true,
        line: true,
        //高亮行数
        maxHighlightLength: 10000,
        //预加载行数
        viewportMargin: 10000,
        smartIndent: true, // 自动缩进
        autoCloseBrackets: true, // 自动补全括号

        matchBrackets: true, //括号匹配
        gutters: ["CodeMirror-lint-markers"],
        lint: true,
        //行高亮
        styleActiveLine: true,
      },
      // 支持切换的语法高亮类型，对应 JS 已经提前引入
      // 使用的是 MIME-TYPE ，不过作为前缀的 text/ 在后面指定时写死了
      modes: [
        {
          value: "css",
          label: "CSS",
        },
        {
          value: "javascript",
          label: "Javascript",
        },
        {
          value: "html",
          label: "XML/HTML",
        },
        {
          value: "x-java",
          label: "Java",
        },
        {
          value: "x-objectivec",
          label: "Objective-C",
        },
        {
          value: "x-python",
          label: "Python",
        },
        {
          value: "x-rsrc",
          label: "R",
        },
        {
          value: "x-sh",
          label: "Shell",
        },
        {
          value: "x-sql",
          label: "SQL",
        },
        {
          value: "x-swift",
          label: "Swift",
        },
        {
          value: "x-vue",
          label: "Vue",
        },
        {
          value: "markdown",
          label: "Markdown",
        },
      ],
      listData: [
        {
          type: "textarea",
          name: "文档标题",
          value: "文档1",
        },
        {
          type: "textarea",
          name: "文档唯一标识",
          value: "文档1",
        },
        {
          type: "textarea",
          name: "文档发布地址",
          value: "https://www.baidu.com",
        },
        {
          type: "select",
          name: "文档模板",
          value: "文档模板1",
          valueList: [
            {
              id: "1",
              name: "文档模板1",
            },
            {
              id: "2",
              name: "文档模板2",
            },
            {
              id: "3",
              name: "文档模板3",
            },
          ],
        },
        {
          type: "label",
          name: "文档状态",
          value: "发布",
        },
      ],
      btnData: [
        {
          label: "编辑稿件",
        },
        {
          label: "保存",
        },
        {
          label: "提交",
        },
        {
          label: "驳回",
        },
        {
          label: "撤稿",
        },
        {
          label: "预览",
        },
        {
          label: "发布",
        },
        {
          label: "导入",
        },
        {
          label: "导出",
        },
        {
          label: "删除",
        },
      ],
    };
  },
  mounted() {
    this.$request("post", "/template/getTemplateList", { type: 3 }, (data) => {
      this.listData[3].valueList = data.result;
      this.$request(
        "post",
        "/doc/getDocInfo",
        { id: this.$route.query.id },
        (data) => {
          this._initialize();
          this.code = data.result.codeContent;
          this.listData[0].value = data.result.name;
          this.listData[1].value = data.result.docOnly;
          this.listData[2].value = data.result.docUrl;
          this.listData[3].value = data.result.templateId;
          this.listData[4].value = data.result.status;
          this.coder.setValue(html_beautify(data.result.codeContent));
        }
      );
    });
    //console.log("aaa", this.$route.query.id);

    // 初始化
  },
  methods: {
    //确认发布
    sureRelease() {
      this.$request(
        "post",
        "/doc/updateDocInfo",
        {
          id: this.$route.query.id,
          userId: "1",
          name: this.listData[0].value,
          docOnly: this.listData[1].value,
          templateId: this.listData[3].value,
          codeContent: this.code,
        },
        (data) => {
          // //console.log(data.result.html);
          // style_html(data.result.html,2," ")
          if (this.currentDialogData.listData[0].value == "1") {
            this.$request(
              "post",
              "/doc/release",
              {
                id: this.$route.query.id,
                userId: "1",
                codeContent: this.code,
              },
              (data) => {
                this.$message.success("发布成功！");
                this.dialogVisible = false;
              }
            );
          } else {
            this.$request(
              "post",
              "/regularRelease/releaseTiming",
              {
                id: this.$route.query.id,
                type: 3,
                releaseTime: moment(
                  this.currentDialogData.listData[1].value
                ).format("YYYY-MM-DD HH:mm:ss"),
              },
              (data) => {
                this.$message.success("定时发布成功！");
                this.dialogVisible = false;
              }
            );
          }
        }
      );
    },
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    // 下方按钮点击事件
    btnClickMethods(item) {
      switch (item.label) {
        case "撤稿":
          this.$request(
            "post",
            "/doc/unpublish",
            {
              id: this.$route.query.id,
              userId: "1",
            },
            (data) => {
              this.$message.success("撤稿成功!");
            }
          );
          break;
        case "驳回":
          this.$request(
            "post",
            "/doc/rejectDoc",
            {
              id: this.$route.query.id,
              userId: "1",
            },
            (data) => {
              this.$message.success("驳回成功!");
            }
          );
          break;
        case "提交":
          this.$request(
            "post",
            "/doc/submitDoc",
            {
              id: this.$route.query.id,
              userId: "1",
            },
            (data) => {
              this.$message.success("提交成功!等待审核~");
            }
          );
          break;
        case "编辑稿件":
          let routeData = this.$router.resolve({
            path: "/docUeditor",
            query: { id: this.$route.query.id },
          });
          window.open(routeData.href, "_blank");
          break;
        case "保存":
          this.$request(
            "post",
            "/doc/updateDocInfo",
            {
              id: this.$route.query.id,
              userId: "1",
              name: this.listData[0].value,
              docOnly: this.listData[1].value,
              templateId: this.listData[3].value,
              codeContent: this.code,
            },
            (data) => {
              // //console.log(data.result.html);
              // style_html(data.result.html,2," ")
              this.$message.success("保存成功");
            }
          );
          break;
        case "预览":
          this.$request(
            "post",
            "/doc/preview",
            {
              id: this.$route.query.id,
              code: this.code,
            },
            (data) => {
              window.open(data.result.previewUrl, "_blank");
            }
          );

          break;
        case "发布":
          this.dialogVisible = true;

          break;
        case "删除":
          this.$confirm("确认删除该么?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/doc/delete",
                {
                  userId: "1",
                  docIdList: [{ id: this.$route.query.id }],
                },
                (data) => {
                  this.$message.success("删除成功！");
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;
        default:
          break;
      }
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {};
      switch (response.result.title) {
        case "上传文档":
          requestData = {
            file: response.result.path,
            type: this.pathType,
          };
          this.$request("post", "", requestData, (data) => {
            this.$message({
              message: "文档添加成功!",
              type: "success",
            });
            this.getAllAgentList();
          });
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传之前的格式校验
     */ beforeAvatarUpload(file) {
      //console.log("上传文件格式:", file.type);
      const isJPG = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 3MB!");
      // }
      return isJPG;
    },
    test() {
      this.$request("post", "/site/saveHtml", { code: this.code }, (data) => {
        // //console.log(data.result.html);
        // style_html(data.result.html,2," ")
        this.$message.success("保存成功");
      });
    },
    // 初始化
    _initialize() {
      // 初始化编辑器实例，传入需要被实例化的文本域对象和默认配置
      this.coder = CodeMirror.fromTextArea(this.$refs.textarea, this.options);
      // 编辑器赋值
      this.coder.setValue(this.value || this.code);

      // 支持双向绑定
      this.coder.on("change", (coder) => {
        this.code = coder.getValue();

        if (this.$emit) {
          this.$emit("input", this.code);
        }
      });
      this.coder.setOption("mode", `text/html`);

      // 尝试从父容器获取语法类型
      // if (this.language) {
      //   // 获取具体的语法类型对象
      //   let modeObj = this._getLanguage(this.language);

      //   // 判断父容器传入的语法是否被支持
      //   if (modeObj) {
      //     this.mode = modeObj.label;
      //   }
      // }
    },
    // 获取当前语法类型
    _getLanguage(language) {
      // 在支持的语法类型列表中寻找传入的语法类型
      return this.modes.find((mode) => {
        // 所有的值都忽略大小写，方便比较
        let currentLanguage = language.toLowerCase();
        let currentLabel = mode.label.toLowerCase();
        let currentValue = mode.value.toLowerCase();

        // 由于真实值可能不规范，例如 java 的真实值是 x-java ，所以讲 value 和 label 同时和传入语法进行比较
        return (
          currentLabel === currentLanguage || currentValue === currentLanguage
        );
      });
    },
    // 更改模式
    changeMode(val) {
      // 修改编辑器的语法配置
      //console.log(val);
      this.coder.setOption("mode", `text/${val}`);

      // 获取修改后的语法
      let label = this._getLanguage(val).label.toLowerCase();

      // 允许父容器通过以下函数监听当前的语法值
      this.$emit("language-change", label);
    },
  },
};
</script>

<style lang="less">
#docInfo {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .CodeMirror {
    flex: 1;
    width: 100%;
  }
  > .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-dialog__body {
        height: auto;
        width: 100%;
        > .itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          > .leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          > .rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-select,
            .el-date-editor {
              width: 100%;
            }
            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  > .optionArea {
    height: auto;
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    background-color: rgba(228, 228, 228, 0.301);
    > .itemOption {
      width: 50%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      > .leftArea {
        width: 180px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      > .rightArea {
        // width: 100px;
        flex: 1;
        height: 100%;
        max-width: 300px;
        margin: 5px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-select {
          width: 100%;
        }
        img {
          cursor: pointer;
        }
      }
    }
  }
  > .btnArea {
    width: 100%;
    height: auto;
    padding: 0 10px 10px 0;
    background-color: rgba(228, 228, 228, 0.301);
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    .itemBtn {
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #5d7bb5;
      border-radius: 10px;
      transition: 0.5s;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #5d7bb5;
        border: 1px solid #ffffff;
      }
    }
  }
}

.CodeMirror-gutters,
.CodeMirror-linenumber {
  left: 0 !important;
}
.CodeMirror-gutter-wrapper {
  left: -30px !important;
}
</style>