<template>
  <div id="videoResource">
    <el-dialog
      :title="currentDialogData.title"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        class="itemOption"
        v-for="(item, index) in currentDialogData.listData"
        :key="index"
      >
        <div class="leftArea">
          <span v-if="item.isNotNull">*</span>
          {{ item.name }}:
        </div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="item.uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <!-- 上传视频 -->
            <!-- <img
              style="width: 100px; height: 50px"
              :src="item.value"
              alt=""
            /> -->
            <div v-if="item.value != ''" class="cc" style="font-size: 12px">
              {{ item.showName }}
            </div>
            <div class="cc" v-else><i class="el-icon-upload"></i></div>
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'pic1'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="item.uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload1"
          >
            <img
              style="width: 100px; height: 50px"
              :src="item.value"
              alt=""
              v-if="item.value != ''"
            />
            <div class="cc" v-else><i class="el-icon-upload"></i></div>
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'textarea'">
          <el-input
            type="textarea"
            :rows="2"
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select
            v-model="item.value"
            :placeholder="'请选择' + item.name"
            filterable
          >
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="rightArea" v-if="item.type == 'dateTime'">
          <el-date-picker
            v-model="item.value"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureAddNew(currentDialogData.title)"
          >确 定</el-button
        >
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div class="contentArea">
      <div class="search">
        <el-input
          v-model="search"
          size="mini"
          placeholder="视频名称搜索"
          @keyup.enter.native="getTableData"
        />
      </div>
      <div class="content">
        <div class="item" v-for="(item, index) in tableData" :key="index">
          <div class="img">
            <img :src="item.pic=='' ? 'http://www.ah-cz.cn/mhwz/上传文档/20230620/11-01-53_视频_1687230113324.png':item.pic" style="width: 98%; height: 98%" />
            <div class="img_pic">
              <i
                class="el-icon-video-play"
                @click="openVideo(item.content)"
              ></i>
            </div>
          </div>
          <div class="bott">
            <div class="title">{{ item.name | controlWord }}</div>
            <div class="bt">
              <div class="bl">{{ item.ext | controlWord1 }}</div>
              <div class="br">
                <i
                  class="el-icon-delete"
                  v-has="tableMenuBtn[1]"
                  @click="deletePic(item)"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <div class="item add" v-has="tableMenuBtn[0]" @click="uploadPic">
          <i class="el-icon-upload"></i><span>上传视频</span>
        </div>
      </div>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <div
      class="openVideo"
      v-if="videoDialog.flag"
      @click="videoDialog.flag = false"
    >
      <video
        style="width: 60%; height: 75%"
        :src="videoDialog.value"
        controls="controls"
      >
        您的浏览器不支持 video 标签。
      </video>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import hasDirective from "../../directives/has1.js";
export default {
  name: "in-coder",
  directives: {
    has: hasDirective,
  },
  props: {
    // 外部传入的内容，用于实现双向绑定
    value: String,
    // 外部传入的语法类型
    language: {
      type: String,
      default: null,
    },
  },
  filters: {
    controlWord(value) {
      if (!value) return "";
      if (value.length > 7) {
        return value.slice(0, 7) + "...";
      }
      return value;
    },
    controlWord1(value) {
      if (!value) return "";
      if (value.length > 8) {
        return value.slice(0, 8) + "...";
      }
      return value;
    },
  },
  data() {
    return {
      multipleSelection: [],
      //每一行的按钮
      rowBtn: [
        {
          type: "authorityData",
          label: "预览",
          page: "videoResource",
        },
      ],
      //表格右上角按钮数据
      tableMenuBtn: [
        {
          type: "authorityData",
          label: "新建",
          page: "videoResource",
          class: "el-icon-plus itemBtn",
          method: "add",
        },
        {
          type: "authorityData",
          label: "删除",
          page: "videoResource",
          class: "el-icon-delete itemBtn",
          method: "delete",
        },
      ],
      //分页数据
      pageSize: 10,
      total: 10,
      currentPage: 1,
      //当前行
      currentRow: {},
      //上传图片数据
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      //弹出窗标志
      dialogVisible: false,
      //currentDialog当前dialog中数据
      currentDialogData: {
        title: "新建视频资源",
        listData: [
          {
            type: "input",
            name: "视频名称",
            value: "",
            isNotNull: true,
          },
          {
            type: "textarea",
            name: "视频描述",
            value: "",
          },
          {
            type: "pic",
            name: "选择视频",
            value: "",
            uploadData: {
              systemName: "cluster_register",
              path: "",
              title: "上传视频",
            },
            isNotNull: true,
          },
          {
            type: "pic1",
            name: "选择封面",
            value: "",
            uploadData: {
              systemName: "cluster_register",
              path: "",
              title: "上传封面",
            },
            isNotNull: true,
          },
        ],
      },
      videoDialog: {
        flag: false,
        value: "",
      },
      search: "",
      tableData: [
        {
          id: "1",
          name: "",
          content: "",
        },
      ],
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      listData: [
        {
          type: "input",
          name: "图片名称",
          value: "",
        },
      ],
      btnData: [
        {
          type: "authorityData",
          page: "videoResource",
          label: "保存",
        },
        {
          type: "authorityData",
          page: "videoResource",
          label: "预览",
        },
        {
          type: "authorityData",
          page: "videoResource",
          label: "删除",
        },
      ],
    };
  },
  mounted() {
    // 初始化列表
    this.getTableData();
  },
  methods: {
    //获得当前表格数据 并默认选择第一行
    getTableData() {
      this.$request(
        "post",
        "/videoResource/getResourceList",
        {
          current: this.currentPage,
          size: this.pageSize,
          search: this.search,
        },
        (data) => {
          this.tableData = data.result.records;
          this.total = data.result.total;
        }
      );
    },
    uploadPic() {
      this.dialogVisible = true;
    },
    /**
     * 根据title判断确认添加新站点/栏目/子栏目
     */
    sureAddNew(title) {
      switch (title) {
        case "新建视频资源":
          if (this.currentDialogData.listData[0].value == "") {
            this.$message.error("视频名称不能为空!");
            return;
          }
          if (this.currentDialogData.listData[2].value == "") {
            this.$message.error("请选择上传视频!");
            return;
          }
          if (this.currentDialogData.listData[3].value == "") {
            this.$message.error("请先上传视频封面!");
            return;
          }
          if (this.currentDialogData.listData[0].value.length > 50) {
            this.$message.error("视频名称过长,请重新重新填写(最大长度50)!");
            return;
          }
          if (this.currentDialogData.listData[1].value.length > 100) {
            this.$message.error("视频描述过长,请重新重新填写(最大长度100)!");
            return;
          }
          this.$request(
            "post",
            "/videoResource/addResource",
            {
              name: this.currentDialogData.listData[0].value,
              url: this.currentDialogData.listData[2].value,
              ext: this.currentDialogData.listData[1].value,
              pic: this.currentDialogData.listData[3].value,
              userId: "1",
            },
            (data) => {
              this.dialogVisible = false;
              this.$message.success("视频资源新建成功!");
              this.getTableData();
            }
          );
          break;
        default:
          break;
      }
    },
    deletePic(item) {
      this.$confirm("删除该图片资源, 是否继续?", "提示", {
        cancelButtonText: "取消",
        confirmButtonText: "确定",
        type: "warning",
      })
        .then(() => {
          this.$request(
            "post",
            "/videoResource/delete",
            {
              userId: "1",
              idList: [item],
            },
            (data) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getTableData();
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    //选择页的变化
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
      //console.log(`当前页: ${val}`);
    },
    //页容量变化
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
      //console.log(`每页 ${val} 条`);
    },
    //选中行发生变化时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      switch (response.title) {
        case "上传视频":
          this.currentDialogData.listData[2].value = response.result.path;
          this.$set(this.currentDialogData.listData[2], "showName", file.name);
          if (this.currentDialogData.listData[0].value == "") {
            this.currentDialogData.listData[0].value = file.name;
          }
          break;
        case "上传封面":
          this.currentDialogData.listData[3].value = response.result.path;
          break;

        default:
          break;
      }
    },
    /**
     * 文件上传之前的格式校验
     */
    beforeAvatarUpload(file) {
      //console.log("上传文件格式:", file.type);
      const isJPG = file.type === "video/mp4";
      // const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 MP4 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 3MB!");
      // }
      return isJPG;
      // return true;
    },
    /**
     * 文件上传之前的格式校验
     */
    beforeAvatarUpload1(file) {
      //console.log("上传文件格式:", file.type);
      const isJPG =
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg";
      // const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 JPG 、JPEG 、PNG 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 3MB!");
      // }
      return isJPG;
    },
    getCurrentAuthorityData() {
      this.$request(
        "post",
        "/authority/getAuthority",
        {
          id: "1",
          key: ["videoResource"],
          relationId: null,
          type: 0,
        },
        (data) => {
          if (
            data.result.videoResource.checkList == null ||
            data.result.videoResource.checkList.length == 0
          ) {
            this.$message.error("越权操作!");
            setTimeout(() => {
              this.$base.exitSys(this);
            }, 1000);
            return;
          }
          localStorage.setItem("videoResource", JSON.stringify(data.result));
        }
      );
    },
    openVideo(value) {
      this.videoDialog.value = value;
      this.videoDialog.flag = true;
    },
  },
  beforeCreate() {
    localStorage.removeItem("videoResource");
  },
  created() {
    this.getCurrentAuthorityData();
  },
};
</script>

<style lang="less">
#videoResource {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  > .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-dialog__body {
        height: auto;
        width: 100%;
        > .itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          > .leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            > span {
              color: #ff0000;
              font-size: 15px;
              width: 8px;
            }
          }
          > .rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-select {
              width: 100%;
            }
            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  > .contentArea {
    width: 100%;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
    position: relative;
    > .search {
      width: 25%;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 10px;
      box-sizing: border-box;
    }
    > .content {
      width: 100%;
      height: 0;
      flex: 1;
      box-sizing: border-box;
      border: 1px solid #ebeef5;
      background-color: #ffffff;
      padding: 10px;
      display: grid;
      grid-row-gap: 10px;
      grid-column-gap: 20px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        ". . . . . . . ."
        ". . . . . . . ."
        ". . . . . . . ."
        ". . . . . . . .";
      > .item {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: 1px solid #ebeef5;
        display: flex;
        flex-direction: column;
        > .img {
          width: 100%;
          height: 0;
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-bottom: 1px solid #ebeef5;
          box-sizing: border-box;
          position: relative;
          > .img_pic {
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.247);
            position: absolute;
            transition: 0.3s;
            opacity: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ffffff;
            font-size: 35px;
          }
          &:hover {
            cursor: pointer;
            .img_pic {
              opacity: 1;
            }
          }
        }
        > .bott {
          width: 100%;
          height: 45px;
          display: flex;
          flex-direction: column;
          > .title {
            width: 100%;
            flex: 1;
            height: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
          }
          > .bt {
            width: 100%;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;
            padding: 0 10px;
            box-sizing: border-box;
            > .bl {
              color: #aaaaaa;
            }
            i {
              cursor: pointer;
              transition: 0.3s;
              &:hover {
                color: #0099ff;
              }
            }
          }
        }
      }
      > .add {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 25px;
        color: #d3d3d3;
        span {
          font-size: 13px;
        }
        transition: 0.3s;
        &:hover {
          cursor: pointer;
          color: rgb(0, 153, 255);
        }
      }
    }
    .el-table {
      position: absolute;
      min-width: 1000px;
      overflow: auto;
      .tableBtnArea {
        display: flex;
      }
      .titleArea {
        display: flex;
        // justify-content: center;
        align-items: center;
        position: relative;
        .newArea {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #01cb94;
          position: absolute;
          float: left;
          left: -10px;
          top: 5px;
        }
        .picArea {
          width: 32px;
          height: 32px;
        }
        .videoArea {
          width: 32px;
          height: 32px;
        }
      }
      .operateArea {
        display: flex;
        align-items: center;
        justify-content: space-around;
        > .btnArea {
          display: flex;
        }
        .itemBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: 0.5s;
          margin: 0 3px;
          &:hover {
            color: #01cb94;
          }
        }
        .el-input {
          max-width: 200px;
        }
      }
      .el-table__cell {
        padding: 3px 0;
      }
    }
  }
  > .block {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  > .optionArea {
    height: auto;
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    background-color: rgba(228, 228, 228, 0.301);
    > .itemOption {
      width: 50%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      > .leftArea {
        width: 180px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      > .rightArea {
        // width: 100px;
        flex: 1;
        height: 100%;
        max-width: 300px;
        margin: 5px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-select {
          width: 100%;
        }
        img {
          cursor: pointer;
        }
      }
    }
  }
  > .btnArea {
    width: 100%;
    height: auto;
    padding: 0 10px 10px 0;
    background-color: rgba(228, 228, 228, 0.301);
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    .itemBtn {
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #5d7bb5;
      border-radius: 10px;
      transition: 0.5s;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #5d7bb5;
        border: 1px solid #ffffff;
      }
    }
  }

  .openVideo {
    width: 100vw;
    height: 100vh;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.315);
    top: 0;
    left: 0;
  }
}
</style>