<template>
  <div id="siteAdditionFile">
    <el-dialog
      :title="currentDialogData.title"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        class="itemOption"
        v-for="(item, index) in currentDialogData.listData"
        :key="index"
      >
        <div class="leftArea">{{ item.name }}:</div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'textarea'">
          <el-input
            type="textarea"
            :rows="2"
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select
            v-model="item.value"
            :placeholder="'请选择' + item.name"
            filterable
          >
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureAddNew(currentDialogData.title)"
          >确 定</el-button
        >
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div class="contentArea">
      <div class="itemOption" v-for="(item, index) in settingData" :key="index">
        <div class="leftArea">{{ item.name }}:</div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input v-model="item.value" placeholder="请输入内容"></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select v-model="item.value" placeholder="请选择" filterable>
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
        <div>{{ item.afterContent }}</div>
      </div>
    </div>
    <div class="optionArea"></div>

    <div class="btnArea">
      <div
        class="itemBtn"
        v-for="(item, index) in btnData"
        @click="btnClick(item, index)"
        :key="index"
        v-has="item"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import hasDirective from "../../directives/has1.js";
export default {
  name: "in-coder",
  directives: {
    has: hasDirective,
  },
  props: {
    // 外部传入的内容，用于实现双向绑定
    value: String,
    // 外部传入的语法类型
    language: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      //当前行
      currentRow: {},
      //上传图片数据
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      //弹出窗标志
      dialogVisible: false,
      //currentDialog当前dialog中数据
      currentDialogData: {
        title: "新建站点关键字",
        listData: [
          {
            type: "input",
            name: "关键字",
            value: "",
          },
          {
            type: "textarea",
            name: "关键字内容",
            value: "",
          },
        ],
      },
      currentPage: 1,
      search: "",
      //设置数据
      settingData: [
        {
          type: "input",
          name: "附件大小",
          value: "",
          afterContent: "单位MB", //后缀内容
        },
        {
          type: "input",
          name: "图片大小",
          value: "",
          afterContent: "单位MB", //后缀内容
        },
        {
          type: "input",
          name: "视频大小",
          value: "",
          afterContent: "单位MB", //后缀内容
        },
      ],
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      listData: [
        {
          type: "input",
          name: "关键字",
          value: "",
        },
        {
          type: "textarea",
          name: "关键字内容",
          value: "",
        },
      ],
      btnData: [
        {
          type: "authorityData",
          label: "应用",
          page: "siteAdditionFile",
        },
        {
          type: "authorityData",
          label: "重置",
          page: "siteAdditionFile",
        },
      ],
    };
  },
  mounted() {
    this.$request(
      "post",
      "/enclosure/getSettings",
      {
        siteId: this.$route.query.id,
      },
      (data) => {
        this.settingData[0].value = data.result.enclosure;
        this.settingData[1].value = data.result.picSetting;
        this.settingData[2].value = data.result.videoSetting;
      }
    );
  },
  methods: {
    //获得当前表格数据 并默认选择第一行
    getTableData() {
      this.$request(
        "post",
        "/site/getKeyWordList",
        {
          id: this.$route.query.id,
        },
        (data) => {
          this.tableData = data.result;
          if (this.tableData.length > 0) {
            this.rowclick(this.tableData[0]);
          }
        }
      );
    },
    // 删除关键字并刷新
    deleteKeyWord(id) {
      this.$request(
        "post",
        "/site/deleteKeyWord",
        {
          id: id,
        },
        (data) => {
          this.currentRow = {};
          this.getTableData();
        }
      );
    },
    //底部按钮点击事件
    btnClick(item, index) {
      switch (item.label) {
        case "应用":
          for (let index = 0; index < this.settingData.length; index++) {
            const element = this.settingData[index];
            if (
              element.value != "" &&
              !this.$formatNum.formatMathDecimal(element.value)
            ) {
              this.$message.error(
                element.name + "格式错误(只能输入数字),请校验后重新输入!"
              );
              return;
            }
            if (element.value.length > 20) {
              this.$message.error(
                element.name + "太长(最大只能长度20),请校验后重新输入!"
              );
              return;
            }
          }
          this.$request(
            "post",
            "/enclosure/apply",
            {
              siteId: this.$route.query.id,
              enclosure: this.settingData[0].value,
              picSetting: this.settingData[1].value,
              videoSetting: this.settingData[2].value,
            },
            (data) => {
              this.$message.success("应用成功");
            }
          );
          break;
        case "重置":
          this.$confirm("确认重置么, 是否继续?", "提示", {
            cancelButtonText: "取消",
            confirmButtonText: "确定",

            type: "warning",
          })
            .then(() => {
              this.settingData[0].value = 2;
              this.settingData[1].value = 2;
              this.settingData[2].value = 2;
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消重置",
              });
            });

          break;

        default:
          break;
      }
    },
    //行点击事件
    rowclick(row, colum, event) {
      this.currentRow = row;
      this.listData[0].value = row.name;
      this.listData[1].value = row.content;
    },
    //表格顶部右上侧按钮点击事件
    tableMenuBtnClick(type) {
      switch (type) {
        //新建文档
        case "add":
          this.dialogVisible = true;
          break;

        default:
          break;
      }
    },
    /**
     * 根据title判断确认添加新站点/栏目/子栏目
     */
    sureAddNew(title) {
      switch (title) {
        case "新建站点关键字":
          this.$request(
            "post",
            "/site/addKeyWord",
            {
              name: this.currentDialogData.listData[0].value,
              id: this.$route.query.id,
              content: this.currentDialogData.listData[1].value,
            },
            (data) => {
              this.dialogVisible = false;
              this.$message.success("新建站点关键字成功!");
              this.$request(
                "post",
                "/site/getKeyWordList",
                {
                  id: this.$route.query.id,
                },
                (data) => {
                  this.tableData = data.result;
                }
              );
            }
          );
          break;
        default:
          break;
      }
    },
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    //筛选标签
    filterType(value, row) {
      return row.type === value;
    },
    filterStatus(value, row) {
      return row.status === value;
    },
    //选择页的变化
    handleCurrentChange(val) {
      this.currentPage = val;
      //console.log(`当前页: ${val}`);
    },
    //页容量变化
    handleSizeChange(val) {
      this.pageSize = val;
      //console.log(`每页 ${val} 条`);
    },
    //选中行发生变化时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {};
      switch (response.result.title) {
        case "上传文档":
          requestData = {
            file: response.result.path,
            type: this.pathType,
          };
          this.$request("post", "", requestData, (data) => {
            this.$message({
              message: "文档添加成功!",
              type: "success",
            });
            this.getAllAgentList();
          });
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传之前的格式校验
     */ beforeAvatarUpload(file) {
      //console.log("上传文件格式:", file.type);
      const isJPG = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 3MB!");
      // }
      return isJPG;
    },
    getCurrentAuthorityData() {
      this.$request(
        "post",
        "/authority/getAuthority",

        {
          id: "1",
          key: ["siteAdditionFile"],
          relationId: this.$route.query.id,
          type: 1,
        },
        (data) => {
          if (
            data.result.siteAdditionFile.checkList == null ||
            data.result.siteAdditionFile.checkList.length == 0
          ) {
            this.$message.error("越权操作!");
            setTimeout(() => {
              this.$base.exitSys(this);
            }, 1000);
            return;
          }
          localStorage.setItem("siteAdditionFile", JSON.stringify(data.result));
        }
      );
    },
  },
  beforeCreate() {
    localStorage.removeItem("siteAdditionFile");
  },
  created() {
    this.getCurrentAuthorityData();
    // let data = {
    //   siteAdditionFile: {
    //     name: "上传附件设置",
    //     checkList: ["应用", "重置"],
    //   },
    // };
    // localStorage.setItem("siteAdditionFile", JSON.stringify(data));
  },
};
</script>

<style lang="less">
#siteAdditionFile {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  > .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-dialog__body {
        height: auto;
        width: 100%;
        > .itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          > .leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          > .rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-select {
              width: 100%;
            }
            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  > .contentArea {
    width: 100%;
    flex: 1;
    overflow: auto;
    position: relative;
    > .itemOption {
      width: 50%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      > .leftArea {
        width: 180px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      > .rightArea {
        // width: 100px;
        flex: 1;
        height: 100%;
        max-width: 300px;
        margin: 5px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-select {
          width: 100%;
        }
        img {
          cursor: pointer;
        }
      }
    }
  }

  > .optionArea {
    height: auto;
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    background-color: rgba(228, 228, 228, 0.301);
    > .itemOption {
      width: 50%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      > .leftArea {
        width: 180px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      > .rightArea {
        // width: 100px;
        flex: 1;
        height: 100%;
        max-width: 300px;
        margin: 5px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-select {
          width: 100%;
        }
        img {
          cursor: pointer;
        }
      }
    }
  }
  > .btnArea {
    width: 100%;
    height: auto;
    padding: 0 10px 10px 0;
    background-color: rgba(228, 228, 228, 0.301);
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    .itemBtn {
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #5d7bb5;
      border-radius: 10px;
      transition: 0.5s;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #5d7bb5;
        border: 1px solid #ffffff;
      }
    }
  }
}
</style>