<template>
  <div id="errorPage">
    <div class="content">
      <i class="el-icon-warning"></i>
      <span>您的IP无权访问</span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
#errorPage {
  width: 100%;
  height: 100%;
  display: flex;
  //   align-items: center;
  justify-content: center;
  > .content {
    width: 500px;
    height: auto;
    color: rgb(255, 145, 0);
    margin-top: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    > i {
      font-size: 80px;
    }
    span {
      margin-top: 20px;
      font-size: 18px;
      font-weight: bolder;
    }
  }
}
</style>