<template>
  <div id="docUeditor">
    <el-dialog
      :title="currentDialogData.title"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        class="itemOption"
        v-for="(item, index) in currentDialogData.listData"
        :key="index"
      >
        <div class="leftArea" v-if="index < 1">{{ item.name }}:</div>
        <!-- <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div> -->
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select v-model="item.value" :placeholder="'请选择' + item.name">
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="itemOption" v-if="currentDialogData.listData[0].value == '2'">
        <div class="leftArea">{{ currentDialogData.listData[1].name }}:</div>
        <div class="rightArea">
          <el-date-picker
            v-model="currentDialogData.listData[1].value"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureRelease()">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div class="custom-format">
      <div class="custom-but" v-for="(menu, index) in editorMenu" :key="index">
        <div
          class="item"
          :id="menu.id"
          v-if="menu.type == 'input'"
          :onclick="menu.click"
        >
          <img :src="menu.icon" alt="" style="width: 15px; height: 15px" />
          {{ menu.lable }}
        </div>

        <div
          class="item"
          :id="menu.id"
          v-else-if="menu.type == 'select'"
          :onclick="menu.click"
        >
          <p
            style="
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            "
          >
            {{ menu.lable }}
          </p>
          <i class="el-icon-caret-bottom"></i>
        </div>
        <div class="item" v-else @click="testCommand(menu.type)">
          <img :src="menu.icon" alt="" style="width: 15px; height: 15px" />
          {{ menu.lable }}
        </div>
      </div>
      <div v-if="!controlFlag" class="zk" @click="changeControl">展开>></div>
      <div v-if="controlFlag" class="zk" @click="changeControl">收起>></div>
    </div>
    <!-- <a
      href="javascript:void(0);"
      id="edui10"
      class="left textSet color"
      title="字体颜色"
      onclick="$EDITORUI['edui10']._onArrowClick(); "
    >
    字体颜色
    </a>
    <button @click="testCommand()">测试</button> -->
    <div id="editor" type="text/plain"></div>
    <div class="contentArea">
      <div class="optionArea">
        <div class="itemOption" v-for="(item, index) in listData" :key="index">
          <div class="leftArea">{{ item.name }}:</div>
          <div class="rightArea" v-if="item.type == 'pic'">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :data="uploadData"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                :src="item.value"
                alt=""
                style="width: 100px; height: 50px"
              />
            </el-upload>
          </div>
          <div class="rightArea labelArea" v-if="item.type == 'label'">
            {{ item.value }}
          </div>
          <div class="rightArea" v-if="item.type == 'input'">
            <el-input v-model="item.value" placeholder="请输入内容"></el-input>
          </div>
          <div class="rightArea" v-if="item.type == 'textarea'">
            <el-input
              type="textarea"
              :rows="2"
              v-model="item.value"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="rightArea" v-if="item.type == 'uediter'">
            <div class="title-uedit">
              <Editor
                style="
                  width: 100%;
                  height: 50px;
                  overflow-y: hidden;
                  border: 1px solid #dcdfe6;
                  border-radius: 4px;
                  line-height: 50px;
                "
                v-model="item.value"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
              />
              <Toolbar
                :editor="editor1"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
            </div>
            <!-- <u_title :title="item.title"></u_title> -->
          </div>
          <div class="rightArea" v-if="item.type == 'dateTime'">
            <el-date-picker
              v-model="item.value"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
          <div class="rightArea" v-if="item.type == 'select'">
            <el-select v-model="item.value" placeholder="请选择">
              <el-option
                v-for="item2 in item.valueList"
                :key="item2.id"
                :label="item2.name"
                :value="item2.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="btnArea">
        <div
          class="itemBtn"
          @click="btnClickMethods(item)"
          v-for="(item, index) in btnData2"
          :key="index"
          v-has="item"
        >
          {{ item.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import VueUeditorWrap from "vue-ueditor-wrap";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import hasDirective from "../../directives/has1.js";
import moment from "moment";
import u_title from "./components/title-uedit.vue";
export default {
  components: { Editor, Toolbar },
  directives: {
    has: hasDirective,
  },
  data() {
    return {
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "mhwz",
        path: "",
        title: "文档logo",
      },
      controlFlag: false,
      editorMenu: [
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/格式清除.png"),
          lable: "格式清除",
          id: "edui16",
          click: "$EDITORUI['edui16']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/一键排版.png"),
          lable: "一键排版",
          id: "edui18",
          click: "$EDITORUI['edui18']._onArrowClick();",
        },
        // {
        //   type: "input",
        //   icon: require("../../assets/img/ueditor_icon/文本校对.png"),
        //   lable: "文本校对",
        //   id: "edui16",
        //   click: "$EDITORUI['edui16']._onClick();",
        // },
        // {
        //   type: "wordupload",
        //   icon: require("../../assets/img/ueditor_icon/导入word.png"),
        //   lable: "导入word",
        //   id: "edui281",
        //   click: "$EDITORUI['edui281']._onClick();",
        // },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/居左对齐.png"),
          lable: "居左对齐",
          id: "edui121",
          click: "$EDITORUI['edui121']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/居中对齐.png"),
          lable: "居中对齐",
          id: "edui122",
          click: "$EDITORUI['edui122']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/居右对齐.png"),
          lable: "居右对齐",
          id: "edui123",
          click: "$EDITORUI['edui123']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/居中.png"),
          lable: "居中",
          id: "edui16",
          click: "$EDITORUI['edui16']._onClick();",
        },
        {
          type: "imageupload",
          icon: require("../../assets/img/ueditor_icon/单图上传.png"),
          lable: "单图上传",
          id: "edui148",
          click: "$EDITORUI['edui148']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/多图上传.png"),
          lable: "多图上传",
          id: "edui153",
          click: "$EDITORUI['edui153']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/视频.png"),
          lable: "视频",
          id: "edui165",
          click: "$EDITORUI['edui165']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/格式刷.png"),
          lable: "格式刷",
          id: "edui17",
          click: "$EDITORUI['edui17']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/撤销.png"),
          lable: "撤销",
          id: "edui6",
          click: "$EDITORUI['edui6']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/重做.png"),
          lable: "重做",
          id: "edui7",
          click: "$EDITORUI['edui7']._onClick();",
        },
        {
          type: "input",
          icon: require("../../assets/img/ueditor_icon/加粗.png"),
          lable: "加粗",
          id: "edui9",
          click: "$EDITORUI['edui9']._onClick();",
        },
        {
          type: "select",
          icon: require("../../assets/img/ueditor_icon/格式清除.png"),
          lable: "宋体",
          id: "edui92",
          click: "$EDITORUI['edui92']._onArrowClick();",
        },
        {
          type: "select",
          icon: require("../../assets/img/ueditor_icon/格式清除.png"),
          lable: "字号",
          id: "edui105",
          click: "$EDITORUI['edui105']._onArrowClick();",
        },
        // {
        //   type: "input",
        //   icon: require("../../assets/img/ueditor_icon/格式清除.png"),
        //   lable: "全文资源",
        //   id: "edui16",
        //   click: "$EDITORUI['edui16']._onClick();",
        // },
      ],
      listData: [
        {
          type: "input",
          name: "作者",
          value: "",
        },
        {
          type: "input",
          name: "信息来源",
          value: "",
        },
        {
          type: "pic",
          name: "封面",
          value: "",
        },
        {
          // type: "input",
          type: "uediter",
          name: "文档标题",
          value: "文档1",
        },
        {
          type: "label",
          name: "文档唯一标识",
          value: "文档1",
        },
        {
          type: "label",
          name: "文档发布地址",
          value: "https://www.baidu.com",
        },

        {
          type: "label",
          name: "文档状态",
          value: "发布",
        },
      ],
      dialogVisible: false,
      currentDialogData: {
        title: "发布模式",
        listData: [
          {
            type: "select",
            name: "发布模式",
            value: "1",
            valueList: [
              {
                id: "1",
                name: "直接发布",
              },
              {
                id: "2",
                name: "定时发布",
              },
            ],
          },
          {
            type: "dateTime",
            name: "发布时间",
            value: "",
          },
        ],
      },
      msg: "",
      editor: {},
      imgList: [],
      addtionfileMenu: [
        {
          label: "附件名称",
        },
        {
          label: "附件大小",
        },
        {
          label: "上传人",
        },
        {
          label: "操作",
        },
      ],
      additionFile: [
        {
          id: "", //附件id
          name: "附件1", //附件名称
          fileSize: "2.1Mb", //附件大小
          uploadName: "当前用户", //上传人名称
          uploadId: "", //上传人id
        },
        {
          id: "", //附件id
          name: "附件2", //附件名称
          fileSize: "2.1Mb", //附件大小
          uploadName: "当前用户", //上传人名称
          uploadId: "", //上传人id
        },
      ],
      btnData2: [
        {
          type: "statusData",
          label: "保存",
          status: "",
          page: "docUeditor",
        },
        {
          type: "statusData",
          label: "提交",
          status: "",
          page: "docUeditor",
        },
        {
          type: "statusData",
          label: "驳回",
          status: "",
          page: "docUeditor",
        },
        {
          type: "statusData",
          label: "撤稿",
          status: "",
          page: "docUeditor",
        },
        {
          type: "statusData",
          label: "预览",
          status: "",
          page: "docUeditor",
        },
        {
          type: "statusData",
          label: "发布",
          status: "",
          page: "docUeditor",
        },
        {
          type: "statusData",
          label: "导入",
          status: "",
          page: "docUeditor",
        },
        {
          type: "statusData",
          label: "导出",
          status: "",
          page: "docUeditor",
        },
        // {
        //   type: "statusData",
        //   label: "删除",
        //   status: "",
        //   page: "docUeditor",
        // },
      ],
      editor1: null,
      html: "<p>hello</p>",
      toolbarConfig: {
        toolbarKeys: ["bold", "italic", "underline", "color"],
      },
      editorConfig: {
        placeholder: "请输入标题...",
        scroll: true, // 是否滚动  默认true
        hoverbarKeys: [],
      },
      mode: "simple", // or 'simple'
    };
  },
  methods: {
    /**
     * 文件上传之前的格式校验
     */
    beforeAvatarUpload(file) {
      //console.log("上传文件格式:", file.type);
      let isType = false;
      if (file.type == "image/png" || file.type == "image/jpeg") {
        isType = true;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isType) {
        this.$message.error("上传文件只能是png格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 2MB!");
      // }
      return isType;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      //console.log("上传成功!", response);
      let requestData = {};
      switch (response.title) {
        case "文档logo":
          this.listData[2].value = response.result.path;
          break;
        default:
          break;
      }
    },
    changeControl() {
      this.controlFlag = !this.controlFlag;
      if (this.controlFlag == true) {
        this.toolBoxchange();
      } else {
        let toolBox = document.getElementsByClassName(
          "edui-editor-toolbarboxouter"
        )[0];
        toolBox.style.height = 0;
      }
    },
    //触发指令
    testCommand(type) {
      this.editor.execCommand(type);
    },
    //确认发布
    sureRelease() {
      this.$request(
        "post",
        "/doc/updateManuscript",
        {
          id: this.$route.query.id,
          userId: "1",
          title: this.listData[3].value,
          name: this.editor1.getText(),
          docOnly: this.listData[4].value,
          pic: this.listData[2].value,
          manuscript: "<content>" + this.getContent() + "</content>",
          contentTxt: this.getContentTxt(),
          author: this.listData[0].value,
          source: this.listData[1].value,
        },
        (data) => {
          if (this.currentDialogData.listData[0].value == "1") {
            this.$request(
              "post",
              "/doc/release",
              {
                id: this.$route.query.id,
                userId: "1",
              },
              (data) => {
                this.$message.success("发布成功！");
                this.dialogVisible = false;
              }
            );
          } else {
            this.$request(
              "post",
              "/regularRelease/releaseTiming",
              {
                id: this.$route.query.id,
                type: 3,
                releaseTime: moment(
                  this.currentDialogData.listData[1].value
                ).format("YYYY-MM-DD HH:mm:ss"),
              },
              (data) => {
                this.$message.success("定时发布成功！");
                this.dialogVisible = false;
              }
            );
          }
        }
      );
    },
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    //下方按钮点击事件
    btnClickMethods(item) {
      switch (item.label) {
        case "保存":
          this.$request(
            "post",
            "/doc/updateManuscript",
            {
              id: this.$route.query.id,
              userId: "1",
              title: this.listData[3].value,
              name: this.editor1.getText(),
              docOnly: this.listData[4].value,
              pic: this.listData[2].value,
              manuscript: "<content>" + this.getContent() + "</content>",
              contentTxt: this.getContentTxt(),
              author: this.listData[0].value,
              source: this.listData[1].value,
            },
            (data) => {
              this.$message.success("保存成功");
              this.getManuscript();
            }
          );
          break;
        case "预览":
          this.$request(
            "post",
            "/doc/previewManuscript",
            {
              id: this.$route.query.id,
              manuscript: "<content>" + this.getContent() + "</content>",
              contentText: this.getContentTxt(),
            },
            (data) => {
              window.open(data.result.previewUrl, "_blank");
            }
          );
          break;
        case "撤稿":
          this.$request(
            "post",
            "/doc/unpublish",
            {
              id: this.$route.query.id,
              userId: "1",
            },
            (data) => {
              this.$message.success("撤稿成功!");
            }
          );
          break;
        case "删除":
          this.$confirm("确认删除该么?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/doc/delete",
                {
                  userId: "1",
                  docIdList: [{ id: this.$route.query.id }],
                },
                (data) => {
                  this.$message.success("删除成功！");
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
        case "驳回":
          this.$request(
            "post",
            "/doc/rejectDoc",
            {
              id: this.$route.query.id,
              userId: "1",
            },
            (data) => {
              this.$message.success("驳回成功!");
            }
          );
          break;
        case "提交":
          this.$request(
            "post",
            "/doc/updateManuscript",
            {
              id: this.$route.query.id,
              userId: "1",
              title: this.listData[3].value,
              name: this.editor1.getText(),
              docOnly: this.listData[4].value,
              pic: this.listData[2].value,
              manuscript: "<content>" + this.getContent() + "</content>",
              contentTxt: this.getContentTxt(),
              author: this.listData[0].value,
              source: this.listData[1].value,
            },
            (data) => {
              this.$request(
                "post",
                "/doc/submitDoc",
                {
                  id: this.$route.query.id,
                  userId: "1",
                },
                (data) => {
                  this.$message.success("提交成功!等待审核~");
                }
              );
            }
          );

          break;
        case "发布":
          this.dialogVisible = true;
          break;

        default:
          break;
      }
    },
    test() {},
    ready(instance) {
      this.editor = instance; // 保存vue-ueditor-wrap实例

      this.editor.addListener(
        "simpleupload_customcomplete",
        function (types, id, link, title, alt) {
          //console.log(link);
          //等等等一堆代码
        }
      );
    },
    but() {
      //console.log(this.msg);
    },
    // 获取纯文本内容
    getContentTxt() {
      return this.editor.getContentTxt();
    },
    // 获取html源代码
    getContent() {
      return this.editor.getContent();
    },
    getManuscript() {
      this.$request(
        "post",
        "/doc/getManuscript",
        {
          userId: "1",
          id: this.$route.query.id,
        },
        (data) => {
          this.editor.setContent("", false);
          this.editor.execCommand("insertHtml", data.result.manuscript);
        }
      );
    },
    toolBoxchange() {
      let toolBox = document.getElementsByClassName(
        "edui-editor-toolbarboxouter"
      )[0];
      toolBox.style.height = "auto";
    },
    getCurrentAuthorityData() {
      this.$request(
        "post",
        "/authority/getAuthority",
        {
          id: "1",
          key: ["docUeditor"],
          relationId: this.$route.query.id,
          type: 3,
        },
        (data) => {
          if (data.result.docUeditor.checkList == null) {
            this.$message.error("越权操作!");
            setTimeout(() => {
              this.$base.exitSys(this);
            }, 1000);
            return;
          }
          localStorage.setItem("docUeditor", JSON.stringify(data.result));
        }
      );
    },
    onCreated(editor) {
      this.editor1 = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
  },
  mounted() {
    // let that = this;
    window.UEDITOR_CONFIG.serverUrl =
      this.$baseurl + "/config?docId=" + this.$route.query.id;
    //console.log(window.UEDITOR_CONFIG);
    this.editor = UE.getEditor("editor");
    //console.log(this.editor);
    this.editor.ready(() => {
      // setTimeout(() => {
      //   this.toolBoxchange();
      // }, 5000);

      //单图上传回调
      this.editor.addListener("simpleUploadCall", (name, data) => {
        // this.imgList.push({
        //   title: data.title,
        //   url: this.editor.getOpt("imageUrlPrefix") + data.url,
        // });
        // //console.log(this.imgList);
        // //console.log(this.editor.getPlainTxt());
        // //console.log();
      });
      // 附件上传回调
      this.editor.addListener("allFileUpload", (name, data) => {
        // //console.log(data);
      });
      // word上传回调
      this.editor.addListener("wordupload", (name, data) => {
        //console.log("word上传", data);
      });
      // 字体字号选择事件的回调
      this.editor.addListener("ready", function (ue) {
        //edui93_content元素为动态生成，因此需要使用以下方法绑定事件
        $("#edui_fixedlayer").on(
          "click",
          "#edui93_content .edui-label.edui-listitem-label.edui-default",
          function () {
            $("#edui92").find("p").html($(this).html().trim("'")); //字体
          }
        );
        $("#edui_fixedlayer").on(
          "click",
          "#edui106_content .edui-label.edui-listitem-label.edui-default",
          function () {
            $("#edui105").find("p").html($(this).html().trim("'")); //字号
          }
        );
      });
      this.editor.addListener("selectionchange", function () {
        //字体、字号变化
        $("#edui92")
          .find("p")
          .html(this.queryCommandValue("fontfamily").split(",")[0]); //字体
        $("#edui105")
          .find("p")
          .html(this.queryCommandValue("fontsize").split(",")[0]); //字号
      });
      this.getManuscript();
    });
  },
  beforeCreate() {
    localStorage.removeItem("docUeditor");
  },
  created() {
    this.$request(
      "post",
      "/doc/getDocInfo",
      { id: this.$route.query.id },
      (data) => {
        this.listData[0].value = data.result.author;
        this.listData[1].value = data.result.source;
        this.listData[2].value = data.result.pic;
        this.listData[3].value = data.result.title;
        // this.listData[3].value = data.result.name;
        this.listData[4].value = data.result.docOnly;
        this.listData[5].value = data.result.docUrl;
        this.listData[6].value = data.result.status;

        //改变按钮中的状态字段
        for (let index = 0; index < this.btnData2.length; index++) {
          const element = this.btnData2[index];
          element.status = data.result.status;
        }
      }
    );
    //console.log("清空localStorage");
    this.getCurrentAuthorityData();

    // let data = {
    //   docUeditor: {
    //     name: "撰稿",
    //     checkList: ["保存", "提交", "驳回", "撤稿", "预览", "发布", "删除"],
    //   },
    // };
    // localStorage.setItem("docUeditor", JSON.stringify(data));
  },

  beforeDestroy() {
    const editor = this.editor1;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>

<style lang="less">
#docUeditor {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  > .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-dialog__body {
        height: auto;
        width: 100%;
        > .itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          > .leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          > .rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-select,
            .el-date-editor {
              width: 100%;
            }
            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  > #editor {
    flex: 3;
    width: 100%;
    overflow: auto;
    // padding: 0 0 25px 0;
    .edui-editor {
      width: 100% !important;
      height: 100%;
      // overflow: auto;
      .edui-editor-iframeholder {
        min-height: 80% !important;
        width: 100% !important;
        // overflow: hidden !important;
      }
    }
  }
  > .contentArea {
    // flex: 1;
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    // overflow-y: auto;
    > .optionArea {
      height: auto;
      width: 100%;
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: center;
      // background-color: rgba(228, 228, 228, 0.301);
      > .itemOption {
        width: 50%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        > .leftArea {
          width: 180px;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        > .rightArea {
          // width: 100px;
          flex: 1;
          height: 100%;
          max-width: 300px;
          margin: 5px 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .el-select {
            width: 100%;
          }
          img {
            cursor: pointer;
          }
        }
      }
    }
    > .btnArea {
      padding: 10px;
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 10%;
    }
    .itemBtn {
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #5d7bb5;
      border-radius: 10px;
      transition: 0.5s;
      margin-left: 20px;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #5d7bb5;
        border: 1px solid #ffffff;
      }
    }
  }
  //工具条
  .edui-editor-toolbarboxouter {
    height: 0;
    overflow: hidden;
  }

  .title-uedit {
    width: 100%;
    height: 100%;
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .custom-format {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .custom-but {
      width: auto;
      height: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 8px;
      box-sizing: border-box;
      border: 1px solid rgba(99, 99, 99, 0.301);
      border-radius: 8px;
      margin: 3px 4px;
      font-size: 11px;
      > .item {
        width: auto;
        height: auto;
        height: 20px;
        line-height: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 3px 8px;
      }
      img {
        margin-right: 3px;
      }
      cursor: pointer;
    }
    .zk {
      position: absolute;
      right: 8px;
      bottom: 0;
      width: auto;
      height: 100%;
      font-size: 12px;
      // margin-right: 8px;
      color: cornflowerblue;
      transition: 0.3s;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
  .edui-default .edui-editor-breadcrumb {
    display: none !important;
  }
}
</style>