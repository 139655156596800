<template>
  <div id="colunmnInfo">
    <el-dialog
      :title="currentDialogData.title"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        class="itemOption"
        v-for="(item, index) in currentDialogData.listData"
        :key="index"
      >
        <div class="leftArea" v-if="index < 1">{{ item.name }}:</div>
        <!-- <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img  style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div> -->
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select
            v-model="item.value"
            :placeholder="'请选择' + item.name"
            filterable
          >
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="itemOption" v-if="currentDialogData.listData[0].value == '2'">
        <div class="leftArea">{{ currentDialogData.listData[1].name }}:</div>
        <div class="rightArea">
          <el-date-picker
            v-model="currentDialogData.listData[1].value"
            type="datetime"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureRelease()">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div id="statisticsArea"></div>
    <div class="optionArea">
      <div class="itemOption" v-for="(item, index) in listData" :key="index">
        <div class="leftArea">{{ item.name }}:</div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
              :headers="headers"
            :on-success="handleAvatarSuccess"
            :on-error="importError"
            :before-upload="beforeAvatarUpload1"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea labelArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input v-model="item.value" placeholder="请输入内容"></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select v-model="item.value" placeholder="请选择" filterable>
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <div class="btnArea">
      <div
        @click="btnClickMethods(item, index)"
        class="itemBtn"
        v-for="(item, index) in btnData"
        :key="index"
        v-has="item"
      >
        <div v-if="item.btnType == 'ordinary'">{{ item.label }}</div>
        <div v-if="item.btnType == 'upload'">
          <el-upload
            class="avatar-uploader"
            :action="item.url"
            :show-file-list="false"
            :data="item.uploadData"
            :on-success="importSuccess"
            :before-upload="beforeAvatarUpload2"
            :on-error="importError"
            accept=".json"
          >
            {{ item.label }}
          </el-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
// 引入全局实例
import * as echarts from "echarts";
import _CodeMirror from "codemirror";
import { js_beautify, css_beautify, html_beautify } from "js-beautify";
// 核心样式
import "codemirror/lib/codemirror.css";
// 引入主题后还需要在 options 中指定主题才会生效
import "codemirror/theme/cobalt.css";

// 需要引入具体的语法高亮库才会有对应的语法高亮效果
// codemirror 官方其实支持通过 /addon/mode/loadmode.js 和 /mode/meta.js 来实现动态加载对应语法高亮库
// 但 vue 貌似没有无法在实例初始化后再动态加载对应 JS ，所以此处才把对应的 JS 提前引入
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/css/css.js";
import "codemirror/mode/xml/xml.js";
import "codemirror/mode/clike/clike.js";
import "codemirror/mode/markdown/markdown.js";
import "codemirror/mode/python/python.js";
import "codemirror/mode/r/r.js";
import "codemirror/mode/shell/shell.js";
import "codemirror/mode/sql/sql.js";
import "codemirror/mode/swift/swift.js";
import "codemirror/mode/vue/vue.js";
import "codemirror/addon/display/autorefresh";
import "codemirror/addon/selection/active-line.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon//lint/lint.css";
import "codemirror/addon/lint/lint.js";
import hasDirective from "../../directives/has1.js";
// import style_html from "../utils/htmlformat.js";

// 尝试获取全局实例
const CodeMirror = window.CodeMirror || _CodeMirror;
import moment from "moment";
export default {
  directives: {
    has: hasDirective,
  },
  name: "in-coder",
  props: {
    // 外部传入的内容，用于实现双向绑定
    value: String,
    // 外部传入的语法类型
    language: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      headers:{},
      mylineChart: {},
      linechartsoption: {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "栏目文章发布数:",
            type: "bar",
            barWidth: "30%",
            data: [10, 52, 200, 334, 390, 330, 220],
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#83bff6" },
                { offset: 0.5, color: "#188df0" },
                { offset: 1, color: "#188df0" },
              ]),
            },
          },
        ],
      },
      dialogVisible: false,
      currentDialogData: {
        title: "发布模式",
        listData: [
          {
            type: "select",
            name: "发布模式",
            value: "1",
            valueList: [
              {
                id: "1",
                name: "直接发布",
              },
              {
                id: "2",
                name: "定时发布",
              },
            ],
          },
          {
            type: "dateTime",
            name: "发布时间",
            value: "",
          },
        ],
      },
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "mhwz",
        path: "",
        title: "栏目logo",
      },
      // 内部真实的内容
      code: "",
      // 默认的语法类型
      mode: "html",
      // 编辑器实例
      coder: null,
      // 默认配置
      options: {
        // 缩进格式
        tabSize: 2,
        // 主题，对应主题库 JS 需要提前引入
        // theme: "cobalt",
        // 显示行号
        lineNumbers: true,
        line: true,
        //高亮行数
        maxHighlightLength: 10000,
        //预加载行数
        viewportMargin: 10000,
        smartIndent: true, // 自动缩进
        autoCloseBrackets: true, // 自动补全括号

        matchBrackets: true, //括号匹配
        gutters: ["CodeMirror-lint-markers"],
        lint: true,
        //行高亮
        styleActiveLine: true,
      },
      // 支持切换的语法高亮类型，对应 JS 已经提前引入
      // 使用的是 MIME-TYPE ，不过作为前缀的 text/ 在后面指定时写死了
      modes: [
        {
          value: "css",
          label: "CSS",
        },
        {
          value: "javascript",
          label: "Javascript",
        },
        {
          value: "html",
          label: "XML/HTML",
        },
        {
          value: "x-java",
          label: "Java",
        },
        {
          value: "x-objectivec",
          label: "Objective-C",
        },
        {
          value: "x-python",
          label: "Python",
        },
        {
          value: "x-rsrc",
          label: "R",
        },
        {
          value: "x-sh",
          label: "Shell",
        },
        {
          value: "x-sql",
          label: "SQL",
        },
        {
          value: "x-swift",
          label: "Swift",
        },
        {
          value: "x-vue",
          label: "Vue",
        },
        {
          value: "markdown",
          label: "Markdown",
        },
      ],
      listData: [
        {
          type: "input",
          name: "栏目名称",
          value: "",
        },
        {
          type: "input",
          name: "栏目唯一标识",
          value: "",
        },

        {
          type: "pic",
          name: "栏目logo",
          value:
            "https://www.baidu.com/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png",
        },
        {
          type: "label",
          name: "栏目发布地址",
          value: "",
        },
        {
          type: "select",
          name: "栏目模板",
          value: "",
          valueList: [
            {
              id: "1",
              name: "栏目模板1",
            },
            {
              id: "2",
              name: "栏目模板2",
            },
            {
              id: "3",
              name: "栏目模板3",
            },
          ],
        },
        // {
        //   type: "select",
        //   name: "子栏目模板",
        //   value: "",
        //   valueList: [
        //     {
        //       id: "1",
        //       name: "栏目模板1",
        //     },
        //     {
        //       id: "2",
        //       name: "栏目模板2",
        //     },
        //     {
        //       id: "3",
        //       name: "栏目模板3",
        //     },
        //   ],
        // },
        {
          type: "select",
          name: "文档模板",
          value: "",
          valueList: [
            {
              id: "1",
              name: "文档模板1",
            },
            {
              id: "2",
              name: "文档模板2",
            },
            {
              id: "3",
              name: "文档模板3",
            },
          ],
        },{
          type: "input",
          name: "栏目摘要",
          value: "",
        },
      ],
      btnData: [
        {
          btnType: "ordinary",
          type: "authorityData",
          page: "columnInfo",
          label: "保存",
        },
        {
          btnType: "ordinary",
          type: "authorityData",
          page: "columnInfo",
          label: "预览",
        },
        {
          btnType: "ordinary",
          type: "authorityData",
          page: "columnInfo",
          label: "发布",
        },
        {
          btnType: "upload",
          type: "authorityData",
          page: "columnInfo",
          url: this.$baseurl + "/column/import",
          uploadData: {
            systemName: "mhwz",
            path: "",
            title: "导入栏目",
            columnId: this.$route.query.id,
          },
          label: "导入",
        },
        {
          btnType: "ordinary",
          type: "authorityData",
          page: "columnInfo",
          label: "导出",
        },
        {
          btnType: "ordinary",
          type: "authorityData",
          page: "columnInfo",
          label: "删除",
        },
      ],
      search: "",
    };
  },
  mounted() {
    this.headers = {
      token:localStorage.getItem("token"),
      userId:localStorage.getItem("userId")
    };
    this.initEcharts();
    this.$request(
      "post",
      "/column/getColumnInfo",
      { id: this.$route.query.id },
      (data) => {
        this.listData[0].value = data.result.name;
        this.listData[1].value = data.result.columnOnly;
        this.listData[2].value = data.result.columnLogo;
        this.listData[3].value = data.result.columnUrl;
        this.listData[4].value = data.result.columnTemplateId;
        this.listData[5].value = data.result.docTemplateId;
        this.listData[6].value = data.result.columnSummary;
        // this.coder.setValue(html_beautify(data.result.codeContent));
        // this.listData[7].value=data.result.docTemplateName
      }
    );
    //填充下方select中值
    this.$request(
      "post",
      "/template/getTemplateByType",
      {
        type: 2,
        siteId: this.$route.query.id,
        search: this.search,
      },
      (data) => {
        this.listData[4].valueList = data.result;
      }
    );
    this.$request(
      "post",
      "/template/getTemplateByType",
      {
        type: 3,
        siteId: this.$route.query.id,
        search: this.search,
      },
      (data) => {
        this.listData[5].valueList = data.result;
      }
    );
  },
  methods: {
    //初始化echarts
    initEcharts() {
      this.mylineChart = this.$echarts.init(
        document.getElementById("statisticsArea")
      );
      let requestData = {
        userId: 1,
        columnId: this.$route.query.id,
      };
      this.$request("post", "/column/getReleaseNum", requestData, (data) => {
        this.linechartsoption.xAxis[0].data = [];
        this.linechartsoption.series[0].data = [];
        data.result.forEach((element) => {
          this.linechartsoption.xAxis[0].data.push(element.date);
          this.linechartsoption.series[0].data.push(element.num);
        });
        this.mylineChart.setOption(this.linechartsoption);
        //console.log("当前echarts配置:", this.linechartsoption);
      });
    },
    //确认发布
    sureRelease() {
      //先调保存接口
      this.$request(
        "post",
        "/column/updateColumnInfo",
        {
          id: this.$route.query.id,
          name: this.listData[0].value,
          columnOnly: this.listData[1].value,
          columnLogo: this.listData[2].value,
          columnUrl: this.listData[3].value,
          columnTemplateId: this.listData[4].value,
          docTemplateId: this.listData[5].value,
          columnSummary: this.listData[6].value,
        },
        (data) => {
          this.$emit("update", {
            type: "updateColumnInfo",
            id: this.$route.query.id,
          });
          if (this.currentDialogData.listData[0].value == "1") {
            this.$request(
              "post",
              "/column/release",
              {
                id: this.$route.query.id,
                codeContent: this.code,
              },
              (data) => {
                this.$message.success("发布成功！");
                this.dialogVisible = false;
              }
            );
          } else {
            this.$request(
              "post",
              "/regularRelease/releaseTiming",
              {
                id: this.$route.query.id,
                type: 2,
                releaseTime: moment(
                  this.currentDialogData.listData[1].value
                ).format("YYYY-MM-DD HH:mm:ss"),
              },
              (data) => {
                this.$message.success("定时发布成功！");
                this.dialogVisible = false;
              }
            );
          }
        }
      );
    },
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    //按钮点击事件
    btnClickMethods(item, index) {
      switch (item.label) {
        case "保存":
          if (this.listData[0].value == "") {
            this.$message.error("栏目名称不能为空!");
            return;
          }
          if (this.listData[1].value == "") {
            this.$message.error("栏目唯一标识不能为空!");
            return;
          }
          if (this.listData[0].value.length > 50) {
            this.$message.error("栏目名称过长,请重新重新填写(最大长度50)!");
            return;
          }
          if (this.listData[1].value.length > 100) {
            this.$message.error(
              "栏目唯一标识过长,请重新重新填写(最大长度100)!"
            );
            return;
          }
          this.$request(
            "post",
            "/column/updateColumnInfo",
            {
              id: this.$route.query.id,
              name: this.listData[0].value,
              columnOnly: this.listData[1].value,
              columnLogo: this.listData[2].value,
              columnTemplateId: this.listData[4].value,
              docTemplateId: this.listData[5].value,
              columnSummary: this.listData[6].value,
            },
            (data) => {
              this.$message.success("保存成功!");
              this.$emit("update", {
                type: "updateColumnInfo",
                id: this.$route.query.id,
              });
            }
          );
          break;
        case "删除":
          this.$confirm("删除该选中项, 是否继续?", "提示", {
            cancelButtonText: "取消",
            confirmButtonText: "确定",

            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/column/deleteColumn",
                {
                  id: this.$route.query.id,
                },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.$emit("update", {
                    type: "deleteColumn",
                    id: this.$route.query.id,
                  });
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;
        case "预览":
          this.$request(
            "post",
            "/column/preview",
            {
              id: this.$route.query.id,
              code: this.code,
            },
            (data) => {
              window.open(data.result.previewUrl, "_blank");
            }
          );
          break;
        case "发布":
          this.dialogVisible = true;
          break;
        case "导入":
          break;
        case "导出":
          window.open(
            this.$baseurl + "/column/export?id=" + this.$route.query.id,
            "_blank"
          );
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {};
      switch (response.title) {
        case "栏目logo":
          this.listData[2].value = response.result.path;
          break;
        default:
          break;
      }
    },

    /**
     * 导入栏目成功
     */
    importSuccess(response, file, fileList) {
      this.$message({
        message: "导入成功!",
        type: "success",
      });
      this.$request(
        "post",
        "/column/getColumnInfo",
        { id: this.$route.query.id },
        (data) => {
          this.listData[0].value = data.result.name;
          this.listData[1].value = data.result.columnOnly;
          this.listData[2].value = data.result.columnLogo;
          this.listData[3].value = data.result.columnUrl;
          this.listData[4].value = data.result.columnTemplateId;
          this.listData[5].value = data.result.docTemplateId;
          this.listData[6].value = data.result.columnSummary;
          // this.coder.setValue(html_beautify(data.result.codeContent));
          // this.listData[7].value=data.result.docTemplateName
        }
      );
    },

    /**
     * 文件上传之前的格式校验
     */
    beforeAvatarUpload1(file) {
      //console.log("上传文件格式:", file.type);
      let isType = false;
      if (file.type == "image/png") {
        isType = true;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isType) {
        this.$message.error("上传文件只能是png格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 2MB!");
      // }
      return isType;
    },
    /**
     * 文件上传之前的格式校验
     */
    beforeAvatarUpload2(file) {
      //console.log("上传文件格式:", file.type);
      let isType = false;
      if (file.type == "application/json") {
        isType = true;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isType) {
        this.$message.error("上传文件只能是json格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 2MB!");
      // }
      return isType;
    },
    test() {
      this.$request("post", "/site/saveHtml", { code: this.code }, (data) => {
        // //console.log(data.result.html);
        // style_html(data.result.html,2," ")
        this.$message.success("保存成功");
      });
    },
    // 初始化
    _initialize() {
      // 初始化编辑器实例，传入需要被实例化的文本域对象和默认配置
      this.coder = CodeMirror.fromTextArea(this.$refs.textarea, this.options);
      // 编辑器赋值
      this.coder.setValue(this.value || this.code);

      // 支持双向绑定
      this.coder.on("change", (coder) => {
        this.code = coder.getValue();

        if (this.$emit) {
          this.$emit("input", this.code);
        }
      });
      this.coder.setOption("mode", `text/html`);

      // 尝试从父容器获取语法类型
      // if (this.language) {
      //   // 获取具体的语法类型对象
      //   let modeObj = this._getLanguage(this.language);

      //   // 判断父容器传入的语法是否被支持
      //   if (modeObj) {
      //     this.mode = modeObj.label;
      //   }
      // }
    },
    // 获取当前语法类型
    _getLanguage(language) {
      // 在支持的语法类型列表中寻找传入的语法类型
      return this.modes.find((mode) => {
        // 所有的值都忽略大小写，方便比较
        let currentLanguage = language.toLowerCase();
        let currentLabel = mode.label.toLowerCase();
        let currentValue = mode.value.toLowerCase();

        // 由于真实值可能不规范，例如 java 的真实值是 x-java ，所以讲 value 和 label 同时和传入语法进行比较
        return (
          currentLabel === currentLanguage || currentValue === currentLanguage
        );
      });
    },
    // 更改模式
    changeMode(val) {
      // 修改编辑器的语法配置
      //console.log(val);
      this.coder.setOption("mode", `text/${val}`);

      // 获取修改后的语法
      let label = this._getLanguage(val).label.toLowerCase();

      // 允许父容器通过以下函数监听当前的语法值
      this.$emit("language-change", label);
    },
    getCurrentAuthorityData() {
      this.$request(
        "post",
        "/authority/getAuthority",
        {
          id: "1",
          key: ["columnInfo"],
          relationId: this.$route.query.id,
          type: 2,
        },
        (data) => {
          if (
            data.result.columnInfo.checkList == null ||
            data.result.columnInfo.checkList.length == 0
          ) {
            this.$message.error("越权操作!");
            setTimeout(() => {
              this.$base.exitSys(this);
            }, 1000);
            return;
          }
          localStorage.setItem("columnInfo", JSON.stringify(data.result));
        }
      );
    },

    /**
     *
     */
    importError(err, file, fileList) {
      this.$message({
        message: "导入失败!",
        type: "error",
      });
    },
  },
  beforeCreate() {
    localStorage.removeItem("columnInfo");
  },
  created() {
    this.getCurrentAuthorityData();
    // let data = {
    //   columnInfo: {
    //     name: "站点回收站",
    //     checkList: ["保存", "预览", "发布", "导入", "导出", "删除"],
    //   },
    // };
    // localStorage.setItem("columnInfo", JSON.stringify(data));
  },
};
</script>

<style lang="less">
#colunmnInfo {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  #statisticsArea {
    flex: 1;
    width: 100%;
  }
  #codeArea {
    overflow: auto;
  }
  .CodeMirror {
    flex: 1;
    // max-height: 500px;
    width: 100%;
  }
  > .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-dialog__body {
        height: auto;
        width: 100%;
        > .itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          > .leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          > .rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-select,
            .el-date-editor {
              width: 100%;
            }
            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  > .optionArea {
    height: auto;
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    background-color: rgba(228, 228, 228, 0.301);
    > .itemOption {
      width: 50%;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      > .leftArea {
        width: 180px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
      > .rightArea {
        // width: 100px;
        flex: 1;
        height: 100%;
        max-width: 300px;
        margin: 5px 10px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-select {
          width: 100%;
        }
        img {
          cursor: pointer;
          width: 100px;
          height: 50px;
        }
      }
    }
  }
  > .btnArea {
    width: 100%;
    height: auto;
    padding: 0 10px 10px 0;
    background-color: rgba(228, 228, 228, 0.301);
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    .itemBtn {
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #5d7bb5;
      border-radius: 10px;
      transition: 0.5s;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #5d7bb5;
        border: 1px solid #ffffff;
      }
    }
  }
}

.CodeMirror-gutters,
.CodeMirror-linenumber {
  left: 0 !important;
}
.CodeMirror-gutter-wrapper {
  left: -30px !important;
}
</style>