/**
 * 返回表格右击所有操作
 */
export function getContentMenuButArray(status, flag) {
    let list = []
    if (flag) {
        // 置顶
        list = [
            [{
                    type: "authorityData",
                    label: "撰稿",
                    text: "撰稿",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-edit-outline",
                }, {
                    label: "新建",
                    text: "新增文档",
                    type: "authorityData",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-plus",
                },
                {
                    label: "调整顺序",
                    text: "调整顺序",
                    type: "statusData",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-place",
                },
                {
                    label: "取消置顶",
                    text: "取消置顶",
                    type: "statusData",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-circle-close",
                },
            ],
            [{
                    type: "statusData",
                    label: "发布",
                    text: "发布文档",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-s-promotion",
                },
                {
                    type: "statusData",
                    label: "撤稿",
                    text: "撤稿",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-folder-delete",
                },
                {
                    type: "statusData",
                    label: "定时撤稿",
                    text: "定时撤稿",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-s-release",
                },
                {
                    type: "statusData",
                    label: "引用",
                    text: "引用文档",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-connection",
                },
                {
                    type: "authorityData",
                    label: "复制",
                    text: "复制文档",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-document-copy",
                },
                {
                    type: "authorityData",
                    label: "移动",
                    text: "移动文档",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-copy-document",
                },
                {
                    type: "authorityData",
                    label: "删除",
                    text: "删除文档",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-delete",
                },
            ],
            [{
                    type: "authorityData",
                    label: "刷新",
                    text: "刷新",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-refresh",
                },
                {
                    type: "authorityData",
                    label: "日志",
                    text: "日志",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-date",
                },
                {
                    type: "authorityData",
                    label: "文档追踪",
                    text: "文档追踪",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-sort",
                },
                {
                    type: "authorityData",
                    label: "预览",
                    text: "预览",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-view",
                },
            ]
        ]
    } else {
        // 非置顶
        list = [
            [{
                    type: "authorityData",
                    label: "撰稿",
                    text: "撰稿",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-edit-outline",
                }, {
                    label: "新建",
                    text: "新增文档",
                    type: "authorityData",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-plus",
                },
                {
                    label: "调整顺序",
                    text: "调整顺序",
                    type: "statusData",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-place",
                },
                {
                    label: "置顶",
                    text: "置顶",
                    type: "statusData",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-top",
                },
            ],
            [{
                    type: "statusData",
                    label: "发布",
                    text: "发布文档",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-s-promotion",
                },
                {
                    type: "statusData",
                    label: "撤稿",
                    text: "撤稿",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-folder-delete",
                },
                {
                    type: "statusData",
                    label: "定时撤稿",
                    text: "定时撤稿",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-s-release",
                },
                {
                    type: "statusData",
                    label: "引用",
                    text: "引用文档",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-connection",
                },
                {
                    type: "authorityData",
                    label: "复制",
                    text: "复制文档",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-document-copy",
                },
                {
                    type: "authorityData",
                    label: "移动",
                    text: "移动文档",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-copy-document",
                },
                {
                    type: "authorityData",
                    label: "删除",
                    text: "删除文档",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-delete",
                },
            ],
            [{
                    type: "authorityData",
                    label: "刷新",
                    text: "刷新",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-refresh",
                },
                {
                    type: "authorityData",
                    label: "日志",
                    text: "日志",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-date",
                },
                {
                    type: "authorityData",
                    label: "文档追踪",
                    text: "文档追踪",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-sort",
                },
                {
                    type: "authorityData",
                    label: "预览",
                    text: "预览",
                    page: "docInfo",
                    status: status,
                    icon: "el-icon-view",
                },
            ]
        ]
    }
    return list

}

/**
 * 返回表格空白处右击处所有操作
 */
export function getNullContentMenuButArray() {
    return [
        [{
            label: "新建",
            text: "新增文档",
            type: "authorityData",
            page: "docInfo",
            status: "",
            icon: "el-icon-plus",
        }, ],
        [{
                type: "authorityData",
                label: "发布",
                text: "发布选中文档",
                page: "docInfo",
                status: "",
                icon: "el-icon-s-promotion",
            },
            {
                type: "authorityData",
                label: "引用",
                text: "引用选中文档",
                page: "docInfo",
                status: "",
                icon: "el-icon-connection",
            },
            {
                type: "authorityData",
                label: "复制",
                text: "复制选中文档",
                page: "docInfo",
                status: "",
                icon: "el-icon-document-copy",
            },
            {
                type: "authorityData",
                label: "移动",
                text: "移动选中文档",
                page: "docInfo",
                status: "",
                icon: "el-icon-copy-document",
            },
            {
                type: "authorityData",
                label: "删除",
                text: "删除选中文档",
                page: "docInfo",
                status: "",
                icon: "el-icon-delete",
            },
        ],
        [{
            type: "authorityData",
            label: "预览",
            text: "预览",
            page: "docInfo",
            status: "",
            icon: "el-icon-view",
        }, {
            type: "authorityData",
            label: "刷新",
            text: "刷新",
            page: "docInfo",
            status: "",
            icon: "el-icon-refresh",
        }, ]
    ]


}

export function getContentButList() {
    let list = []
    let array = getNullContentMenuButArray()
    for (let index = 0; index < array.length; index++) {
        const element = array[index];
        for (let j = 0; j < element.length; j++) {
            const ele = element[j];
            list.push(JSON.parse(JSON.stringify(ele)))
        }
    }
    return list
}