<template>
  <div id="loadding">
      欢迎您登录内网网站</br>
      后端管理系统
      </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
#loadding {
  width: 100%;
  height: 100%;
  background: url("../assets/img/loadding_1.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  padding-top: 13%;
  box-sizing: border-box;
  color: #ffffff;
  font-size: 5vh;
  font-weight: 600;
  font-family: "宋体";
  letter-spacing: 0.3vw;
  line-height: 7vh;
  text-align: center;
  //   -webkit-text-stroke: 1.5px #fff;
}
</style>