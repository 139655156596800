<template>
  <div id="siteManage">
    <el-dialog
      :title="currentDialogData.title"
      :visible.sync="dialogVisible"
      width="60%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        class="itemOption"
        v-for="(item, index) in currentDialogData.listData"
        :key="index"
      >
        <div class="leftArea">
          <span v-if="item.isNotNull">*</span>{{ item.name }}:
        </div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload
            class="avatar-uploader"
            :action="uploadUrl"
            :show-file-list="false"
            :data="uploadData"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select
            v-model="item.value"
            :placeholder="'请选择' + item.name"
            filterable
          >
            <el-option
              v-for="item2 in item.valueList"
              :key="item2.id"
              :label="item2.name"
              :value="item2.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureAddNew(currentDialogData.title)"
          >确 定</el-button
        >
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div class="leftArea">
      <div class="leftAreaContent">
        <el-input placeholder="输入关键字过滤站点或栏目" v-model="filterText">
        </el-input>
        <el-tree
          ref="siteTree"
          :filter-node-method="filterNode"
          :data="siteData"
          :props="defaultProps"
          highlight-current
          :expand-on-click-node="false"
          @node-click="handleNodeClick"
          node-key="id"
        >
          <span class="custom-tree-node" slot-scope="{ node, data }">
            <span>
              <img src="@/assets/img/站点.png" v-if="data.flag == '1'" alt="" />
              <img src="@/assets/img/栏目.png" v-if="data.flag == '2'" alt="" />
              <img
                src="@/assets/img/子栏目.png"
                v-if="data.flag == '3'"
                alt=""
              />
              {{ node.label }}
            </span>
            <span class="tb" v-if="data.flag != '1'" style="margin-right: 15px">
              <el-tooltip
                class="item"
                effect="dark"
                content="上移"
                placement="left"
              >
                <i
                  class="el-icon-upload2"
                  style="margin-right: 5px"
                  @click.stop="updateColumnSort(0, data)"
                ></i>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="下移"
                placement="right"
              >
                <i
                  class="el-icon-download"
                  @click.stop="updateColumnSort(1, data)"
                ></i>
              </el-tooltip>
            </span>
          </span>
        </el-tree>
      </div>

      <div class="leftAreaBut">
        <div
          class="but"
          @click="addNew(item)"
          v-for="(item, index) in menuBut"
          :key="index"
          v-has="item"
        >
          {{ item.label }}
        </div>
        <div :class="{ btn: true, btnHide: btnHide }">
          <i class="el-icon-document-add"> </i>
        </div>
      </div>
    </div>
    <div class="siteContentArea">
      <div id="siteMenu">
        <div class="topArea">
          <div
            @click="menuClick(item, index)"
            :class="{ itemMenu: true, itemMenuActive: currentIndex == index }"
            v-for="(item, index) in menuData"
            :key="index"
          >
            <div>{{ item.name }}</div>
          </div>
        </div>
        <div class="contentArea">
          <div class="linkArea">
            <div
              class="itemLinkArea"
              v-for="(item, index) in navigationData"
              :key="index"
            >
              <div><i class="el-icon-d-arrow-right"></i></div>
              <div>{{ item.name }}</div>
            </div>
          </div>
          <div class="detailArea">
            <router-view @update="update" v-if="isRouterAlive"></router-view>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as siteUtils from "./site.js";
import has from "../../directives/has1.js";
export default {
  directives: {
    has: has,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      currentSiteId: null,
      btnHide: false,
      //上传图片数据
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "栏目logo",
      },
      //弹出窗标志
      dialogVisible: false,
      isRouterAlive: true,
      filterText: "",
      userData: { userId: "1" },
      //菜单数据
      currentIndex: 0,
      menuData: [
        {
          id: "",
          name: "站点信息",
        },
        {
          id: "",
          name: "栏目回收站",
        },
        {
          id: "",
          name: "文档回收站",
        },
        {
          id: "",
          name: "上传/附件设置",
        },
        {
          id: "",
          name: "智能建站",
        },
      ],

      siteData: [],
      // 站点数据
      // siteData: [
      //   {
      //     id: "1",
      //     name: "站点 1",
      //     flag: "1", // 站点
      //     children: [
      //       {
      //         id: "1-1",
      //         name: "栏目 1-1",
      //         flag: "2", // 只能创建子栏目
      //         children: [
      //           {
      //             id: "1-1-1",
      //             flag: "3", // 只能创建文档
      //             name: "子栏目 1-1-1",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     id: "2",
      //     name: "站点 2",
      //     flag: "1", // 站点
      //     children: [
      //       {
      //         id: "2-1",
      //         name: "栏目 2-1",
      //         flag: "2", // 只能创建子栏目
      //         children: [
      //           {
      //             id: "2-1-1",
      //             name: "子栏目 2-1-1",
      //             flag: "3", // 只能创建文档
      //           },
      //         ],
      //       },
      //       {
      //         id: "2-2",
      //         name: "栏目 2-2",
      //         flag: "2", // 只能创建子栏目
      //         children: [
      //           {
      //             id: "2-2-1",
      //             name: "子栏目 2-2-1",
      //             flag: "3", // 只能创建文档
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     id: "3",
      //     name: "站点 3",
      //     flag: "1", // 站点
      //     children: [
      //       {
      //         id: "3-1",
      //         name: "栏目 3-1",
      //         flag: "2", // 只能创建子栏目
      //         children: [
      //           {
      //             id: "3-1-1",
      //             name: "子栏目 3-1-1",
      //             flag: "3", // 只能创建文档
      //           },
      //         ],
      //       },
      //       {
      //         id: "3-2",
      //         name: "栏目 3-2",
      //         flag: "2", // 只能创建子栏目
      //         children: [
      //           {
      //             id: "3-2-1",
      //             name: "子栏目 3-2-1",
      //             flag: "3", // 只能创建文档
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     id: "4",
      //     name: "站点 4",
      //     flag: "1", // 站点
      //     children: [
      //       {
      //         id: "4-1",
      //         name: "栏目 4-1",
      //         flag: "2", // 只能创建子栏目
      //         children: [
      //           {
      //             id: "4-1-1",
      //             name: "子栏目 4-1-1",
      //             flag: "3", // 只能创建文档
      //           },
      //         ],
      //       },
      //       {
      //         id: "4-2",
      //         name: "栏目 4-2",
      //         flag: "2", // 只能创建子栏目
      //         children: [
      //           {
      //             id: "4-1",
      //             name: "子栏目 4-2-1",
      //             flag: "3", // 只能创建文档
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ],
      // 树节点的默认数据key
      defaultProps: {
        children: "children",
        label: "name",
      },
      //下方按钮数据
      menuBut: [
        {
          label: "新建站点",
          type: "authorityData",
          page: "siteManage",
          active: "createSite",
        },
      ],

      //currentDialog当前dialog中数据
      currentDialogData: {
        title: "",
        listData: [],
      },
      //dialog所有数据 根据按钮在其中去切换
      dialogData: [
        {
          title: "新建站点",
          listData: [
            {
              type: "input",
              name: "站点名称",
              value: "",
              isNotNull: true,
            },
            {
              type: "input",
              name: "唯一标识",
              value: "",
              isNotNull: true,
            },
            {
              type: "input",
              name: "存放位置",
              value: "",
            },
          ],
        },
        {
          title: "新建栏目",
          listData: [
            {
              type: "input",
              name: "栏目名称",
              value: "",
              isNotNull: true,
            },
            {
              type: "select",
              name: "栏目类型",
              value: "",
              valueList: [
                {
                  id: "1",
                  name: "只含子栏目",
                },
                {
                  id: "0",
                  name: "只含文档",
                },
              ],
              isNotNull: true,
            },
            {
              type: "pic",
              name: "栏目logo",
              value:
                "https://www.baidu.com1/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png",
            },
            {
              type: "select",
              name: "栏目模板",
              value: "",
              valueList: [
                {
                  id: "1",
                  name: "栏目模板1",
                },
                {
                  id: "2",
                  name: "栏目模板2",
                },
                {
                  id: "3",
                  name: "栏目模板3",
                },
              ],
            },
            {
              type: "select",
              name: "文档模板",
              value: "",
              valueList: [
                {
                  id: "1",
                  name: "文档模板1",
                },
                {
                  id: "2",
                  name: "文档模板2",
                },
                {
                  id: "3",
                  name: "文档模板3",
                },
              ],
            },
            {
              type: "input",
              name: "唯一标识",
              value: "",
              isNotNull: true,
            },
            {
              type: "input",
              name: "存放位置",
              value: "",
            },
          ],
        },
        {
          title: "新建子栏目",
          listData: [
            // {
            //   type: "input",
            //   name: "栏目唯一标识",
            //   value: "",
            // },
            {
              type: "input",
              name: "栏目名称",
              value: "",
              isNotNull: true,
            },
            {
              type: "select",
              name: "栏目类型",
              value: "",
              isNotNull: true,
              valueList: [
                {
                  id: "1",
                  name: "只含子栏目",
                },
                {
                  id: "0",
                  name: "只含文档",
                },
              ],
            },
            {
              type: "pic",
              name: "栏目logo",
              value:
                "https://www.baidu.com1/img/PCtm_d9c8750bed0b3c7d089fa7d55720d6cf.png",
            },
            {
              type: "select",
              name: "栏目模板",
              value: "",
              isNotNull: true,
              valueList: [
                {
                  id: "1",
                  name: "栏目模板1",
                },
                {
                  id: "2",
                  name: "栏目模板2",
                },
                {
                  id: "3",
                  name: "栏目模板3",
                },
              ],
            },
            // {
            //   type: "select",
            //   name: "子栏目模板",
            //   value: "",
            //   valueList: [
            //     {
            //       id: "1",
            //       name: "栏目模板1",
            //     },
            //     {
            //       id: "2",
            //       name: "栏目模板2",
            //     },
            //     {
            //       id: "3",
            //       name: "栏目模板3",
            //     },
            //   ],
            // },
            {
              type: "select",
              name: "文档模板",
              value: "",
              isNotNull: true,
              valueList: [
                {
                  id: "1",
                  name: "文档模板1",
                },
                {
                  id: "2",
                  name: "文档模板2",
                },
                {
                  id: "3",
                  name: "文档模板3",
                },
              ],
            },
            {
              type: "input",
              name: "唯一标识",
              value: "",
              isNotNull: true,
            },
            {
              type: "input",
              name: "存放位置",
              value: "",
            },
          ],
        },
        {
          title: "新建文档",
          listData: [
            {
              type: "input",
              name: "文档名称",
              value: "文档1",
            },
            {
              type: "select",
              name: "文档模板",
              value: "文档模板1",
              valueList: [
                {
                  id: "1",
                  name: "文档模板1",
                },
                {
                  id: "2",
                  name: "文档模板2",
                },
                {
                  id: "3",
                  name: "文档模板3",
                },
              ],
            },
          ],
        },
      ],

      //导航栏数据
      navigationData: [
        // {
        //   id: "",
        //   name: "栏目1",
        //   childType: "1",
        // },
        // {
        //   id: "",
        //   name: "子栏目1",
        //   childType: "1",
        // },
        // {
        //   id: "",
        //   name: "子栏目子栏目1",
        //   childType: "2",
        // },
      ],
    };
  },
  watch: {
    filterText(val) {
      this.$refs.siteTree.filter(val);
    },
  },
  methods: {
    /**
     * 子级路由触发更新
     */
    async update($event) {
      let type = $event.type;
      //console.log($event);
      await this.fetchData();
      switch (type) {
        case "updateSiteInfo":
          if (this.siteData.length > 0) {
            this.$nextTick(() => {
              this.$refs.siteTree.setCurrentKey($event.id);
            });
            this.$router.push({
              path: "/siteManage/siteInfo",
              query: { id: $event.id },
            });
            this.reload();
          }
          break;
        case "siteDelete":
          if (this.siteData.length > 0) {
            this.$nextTick(() => {
              this.$refs.siteTree.setCurrentKey(this.siteData[0].id);
            });
            this.$router.push({
              path: "/siteManage/siteInfo",
              query: { id: this.siteData[0].id },
            });
            this.reload();
          }
          break;
        case "updateColumnInfo":
          if (this.siteData.length > 0) {
            this.$nextTick(() => {
              this.$refs.siteTree.setCurrentKey($event.id);
            });
            this.$router.push({
              path: "/siteManage/columnInfo",
              query: { id: $event.id },
            });
            this.reload();
          }
          break;
        case "deleteColumn":
          if (this.siteData.length > 0) {
            this.$nextTick(() => {
              this.$refs.siteTree.setCurrentKey(this.siteData[0].id);
            });
            this.$router.push({
              path: "/siteManage/siteInfo",
              query: { id: this.siteData[0].id },
            });
            this.reload();
          }
          break;
        default:
          break;
      }
    },
    /**
     * 根据title判断确认添加新站点/栏目/子栏目
     */
    sureAddNew(title) {
      switch (title) {
        case "新建站点":
          if (this.currentDialogData.listData[0].value == "") {
            this.$message.error("站点名称不能为空!");
            return;
          }
          if (this.currentDialogData.listData[1].value == "") {
            this.$message.error("站点唯一标识不能为空!");
            return;
          }
          if (this.currentDialogData.listData[0].value.length > 50) {
            this.$message.error("站点名称过长,请重新重新填写(最大长度50)!");
            return;
          }
          if (this.currentDialogData.listData[1].value.length > 100) {
            this.$message.error(
              "站点唯一标识过长,请重新重新填写(最大长度100)!"
            );
            return;
          }
          this.$request(
            "post",
            "/site/addSite",
            {
              name: this.currentDialogData.listData[0].value,
              siteOnly: this.currentDialogData.listData[1].value,
              storageLocation: this.currentDialogData.listData[2].value,
              userId: 1,
            },
            (data) => {
              this.dialogVisible = false;
              this.$message.success("新建站点成功!");
              //新建成功后 刷新站点
              this.fetchData();
            }
          );
          break;
        case "新建栏目":
          if (this.currentDialogData.listData[0].value == "") {
            this.$message.error("栏目名称不能为空!");
            return;
          }
          if (this.currentDialogData.listData[5].value == "") {
            this.$message.error("栏目唯一标识不能为空!");
            return;
          }
          if (this.currentDialogData.listData[0].value.length > 50) {
            this.$message.error("栏目名称过长,请重新重新填写(最大长度50)!");
            return;
          }
          if (this.currentDialogData.listData[5].value.length > 100) {
            this.$message.error(
              "栏目唯一标识过长,请重新重新填写(最大长度100)!"
            );
            return;
          }
          this.$request(
            "post",
            "/column/addColumn",
            {
              name: this.currentDialogData.listData[0].value,
              siteId: this.$route.query.id,
              fatherId: "",
              columnType: this.currentDialogData.listData[1].value,
              columnLogo: this.currentDialogData.listData[2].value,
              columnTemplateId: this.currentDialogData.listData[3].value,
              docTemplateId: this.currentDialogData.listData[4].value,
              columnOnly: this.currentDialogData.listData[5].value,
              storageLocation: this.currentDialogData.listData[6].value,
              userId: 1,
            },
            (data) => {
              this.dialogVisible = false;
              this.$message.success("新建栏目成功!");
              //新建成功后 刷新站点树
              this.fetchData();
            }
          );
          break;
        case "新建子栏目":
          if (this.currentDialogData.listData[0].value == "") {
            this.$message.error("栏目名称不能为空!");
            return;
          }
          if (this.currentDialogData.listData[5].value == "") {
            this.$message.error("栏目唯一标识不能为空!");
            return;
          }
          if (this.currentDialogData.listData[0].value.length > 50) {
            this.$message.error("栏目名称过长,请重新重新填写(最大长度50)!");
            return;
          }
          if (this.currentDialogData.listData[5].value.length > 100) {
            this.$message.error(
              "栏目唯一标识过长,请重新重新填写(最大长度100)!"
            );
            return;
          }
          this.$request(
            "post",
            "/column/addColumn",
            {
              name: this.currentDialogData.listData[0].value,
              // columnOnly: this.currentDialogData.listData[0].value,
              siteId: "",
              fatherId: this.$route.query.id,
              columnType: this.currentDialogData.listData[1].value,
              columnLogo: this.currentDialogData.listData[2].value,
              columnTemplateId: this.currentDialogData.listData[3].value,
              // childColumnTemplateId: this.currentDialogData.listData[5].value,
              docTemplateId: this.currentDialogData.listData[4].value,
              columnOnly: this.currentDialogData.listData[5].value,
              storageLocation: this.currentDialogData.listData[6].value,
              userId: 1,
            },
            (data) => {
              this.dialogVisible = false;
              this.$message.success("新建栏目成功!");
              //新建成功后 刷新站点树
              this.fetchData();
            }
          );
          break;

        default:
          break;
      }
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {};
      switch (response.title) {
        case "栏目logo":
          //console.log("");
          this.currentDialogData.listData[2].value = response.result.path;
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传之前的格式校验
     */ beforeAvatarUpload(file) {
      //console.log("上传文件格式:", file.type);
      const isJPG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 png 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 3MB!");
      // }
      return isJPG;
    },
    //创建新站点/新栏目/新子栏目
    addNew(item) {
      switch (item.label) {
        case "新建站点":
          this.currentDialogData.title = this.dialogData[0].title;
          this.currentDialogData.listData = this.dialogData[0].listData;
          this.$request(
            "post",
            "/template/getTemplateByType",
            {
              type: 1,
              siteId: this.currentSiteId,
              search: "",
            },
            (data) => {
              this.dialogVisible = true;
              this.dialogData[0].listData[1].valueList = data.result;
            }
          );
          break;
        case "新建栏目":
          this.currentDialogData.title = this.dialogData[1].title;
          this.currentDialogData.listData = this.dialogData[1].listData;
          this.$request(
            "post",
            "/template/getTemplateByType",
            {
              type: 2,
              siteId: this.currentSiteId,
              search: "",
            },
            (data) => {
              this.dialogVisible = true;
              this.dialogData[1].listData[3].valueList = data.result;
            }
          );
          this.$request(
            "post",
            "/template/getTemplateByType",
            {
              type: 3,
              siteId: this.currentSiteId,
              search: "",
            },
            (data) => {
              this.dialogVisible = true;
              this.dialogData[1].listData[4].valueList = data.result;
            }
          );
          break;
        case "新建子栏目":
          this.currentDialogData.title = this.dialogData[2].title;
          this.currentDialogData.listData = this.dialogData[2].listData;
          this.$request(
            "post",
            "/template/getTemplateByType",
            {
              type: 2,
              siteId: this.currentSiteId,
              search: "",
            },
            (data) => {
              this.dialogVisible = true;
              this.dialogData[2].listData[3].valueList = data.result;
              // this.dialogData[2].listData[5].valueList = data.result;
            }
          );
          this.$request(
            "post",
            "/template/getTemplateByType",
            {
              type: 3,
              siteId: this.currentSiteId,
              search: "",
            },
            (data) => {
              this.dialogData[2].listData[4].valueList = data.result;
            }
          );
          break;
        default:
          break;
      }
    },
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    // 用作路由刷新
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    /**
     * 站点筛选
     */
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    columnClick(index) {
      this.columnIndex = index;
    },
    childClick(index) {
      this.childIndex = index;
    },
    menuClick(item, index) {
      this.currentIndex = index;
      switch (item.name) {
        case "站点信息":
          this.$router.push({
            path: "/siteManage/siteInfo",
            query: { id: this.$route.query.id }, //
          });
          break;
        case "站点关键字":
          this.$router.push({
            path: "/siteManage/siteKey",
            query: { id: this.$route.query.id }, //
          });
          break;
        case "站点发文统计":
          this.$router.push({
            path: "/siteManage/siteStatistics",
            query: { id: this.$route.query.id }, //
          });
          break;
        case "站点回收站":
          this.$router.push({
            path: "/siteManage/siteTrash",
            query: { id: this.$route.query.id }, //
          });
          break;
        case "上传/附件设置":
          this.$router.push({
            path: "/siteManage/siteAdditionFile",
            query: { id: this.$route.query.id }, //
          });
          break;
        case "智能建站":
          this.$router.push({
            path: "/siteManage/IntelligentSiteBuilding",
            query: { id: this.$route.query.id }, //
          });
          break;
        case "栏目信息":
          this.$router.push({
            path: "/siteManage/columnInfo",
            query: { id: this.$route.query.id }, //
          });
          break;
        case "栏目回收站":
          this.$router.push({
            path: "/siteManage/columnTrash",
            query: { id: this.$route.query.id }, //
          });
          break;
        case "文档回收站":
          this.$router.push({
            path: "/siteManage/docTrash",
            query: { id: this.$route.query.id }, //
          });
          break;
        case "文档列表":
          this.$router.push({
            path: "/siteManage/docInfo",
            query: { id: this.$route.query.id }, //
          });
          break;
        case "数据统计":
          this.$router.push({
            path: "/siteManage/dataStatistics",
            query: { id: this.$route.query.id }, //
          });
          break;
        default:
          break;
      }
    },
    // 树节点点击事件
    handleNodeClick(data, node, item) {
      this.btnHide = false;
      if (data.flag == "1") {
        this.currentSiteId = data.id;
      } else {
        this.currentSiteId = data.siteId;
      }
      this.judgeMenuButShow(data.flag, data.id);
    },
    judgeMenuButShow(flag, id) {
      this.currentIndex = 0;
      this.menuBut = [];
      this.menuData = [];
      switch (flag) {
        case "1":
          // 点击的是站点

          this.menuBut = [
            {
              label: "新建站点",
              type: "authorityData",
              page: "siteManage",
              active: "createSite",
            },
            {
              label: "新建栏目",
              type: "authorityData",
              page: "siteManage",
              active: "createColumn",
            },
          ];
          siteUtils.getSiteMenuList(this, id);
          this.$router.push({
            path: "/siteManage/siteInfo",
            query: { id: id },
          });
          this.reload();
          break;
        case "2":
          // 点击的是只能创建子栏目的栏目
          this.menuBut = [
            {
              label: "新建子栏目",
              type: "authorityData",
              page: "siteManage",
              active: "createChild",
            },
          ];
          siteUtils.getColumnMenuList(this, id);
          this.$router.push({
            path: "/siteManage/columnInfo",
            query: { id: id },
          });
          this.reload();
          break;
        case "3":
          // 点击的是只能创建文档的栏目
          this.menuBut = [];
          this.btnHide = true; //关闭下方按钮
          siteUtils.getColumnMenuList2(this, id);
          this.$router.push({
            path: "/siteManage/docInfo",
            query: { id: id },
          });
          this.reload();
          break;

        default:
          break;
      }
    },

    getCurrentAuthorityData() {
      this.$request(
        "post",
        "/authority/getAuthority",
        {
          id: "1",
          key: ["siteManage"],
          relationId: null,
          type: 0,
        },
        (data) => {
          if (
            data.result.siteManage.checkList == null ||
            data.result.siteManage.checkList.length == 0
          ) {
            this.$message.error("越权操作!");
            setTimeout(() => {
              this.$base.exitSys(this);
            }, 1000);
            return;
          }
          localStorage.setItem("siteManage", JSON.stringify(data.result));
        }
      );
    },
    /**
     * 上下移动栏目,排序
     */
    updateColumnSort(flag, column) {
      //console.log(flag, column);
      let requestData = {
        userId: "1",
        columnId: column.id,
        flag: flag,
      };
      this.$request(
        "post",
        "/column/updateColumnSort",
        requestData,
        async (data) => {
          await this.fetchData();
        }
      );
    },
    fetchData() {
      // 记录当前展开的节点
      let nodesMap = this.$refs.siteTree.store.nodesMap;
      let expandedNodes = Object.keys(nodesMap).filter(
        (key) => nodesMap[key].expanded
      );
      //获得站点下栏目信息
      this.$request(
        "post",
        "/site/getSiteTree",
        {
          userId: this.userData.userId,
        },
        (data) => {
          this.siteData = data.result;
          if (this.siteData.length > 0) {
            this.menuBut = [
              {
                label: "新建站点",
                type: "authorityData",
                page: "siteManage",
                active: "createSite",
              },
              {
                label: "新建栏目",
                type: "authorityData",
                page: "siteManage",
                active: "createColumn",
              },
            ];
            this.$nextTick(() => {
              this.currentSiteId = this.$route.query.id
                ? this.$route.query.id
                : this.siteData[0].id;
              console.log("ID", this.currentSiteId, this.$route.query.id);
              this.$refs.siteTree.setCurrentKey(this.currentSiteId);
            });
            setTimeout(() => {
              if (this.filterText !== "") {
                this.$refs.siteTree.filter(this.filterText);
              } else {
                console.log("查看所有展开节点", expandedNodes, nodesMap);
                expandedNodes.forEach((key) => (nodesMap[key].expanded = true));
              }
            }, 0);
          }
        }
      );
    },
  },
  async mounted() {
    //console.log("siteManage界面");
    //获得站点下栏目信息

    await this.fetchData();
    this.$router.push({
      path: "/siteManage/siteInfo",
      query: { id: this.siteData[0].id },
    });
    // 初始化界面,设置树节点响应节点 默认第一个站点ID
    // setTimeout(() => {
    //   // //console.log("获取栏目数据:", this.columnData);
    //   this.$nextTick(() => {
    //     this.$refs.siteTree.setCurrentKey(this.siteData[0].id);
    //   });
    // }, 2000);
  },
  beforeCreate() {
    localStorage.removeItem("siteManage");
  },
  created() {
    this.getCurrentAuthorityData();
    // let data = {
    //   siteManage: {
    //     name: "站点管理",
    //     checkList: ["新建站点", "新建栏目", "新建子栏目"],
    //   },
    // };
    // localStorage.setItem("siteManage", JSON.stringify(data));
  },
};
</script>

<style lang="less">
#siteManage {
  width: 100%;
  height: 100%;
  display: flex;
  > .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-dialog__body {
        height: auto;
        width: 100%;
        > .itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          > .leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            > span {
              width: 8px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              color: #ee0000;
              font-size: 15px;
            }
          }
          > .rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-select {
              width: 100%;
            }
            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  > .leftArea {
    flex: 2.2;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-left: 2px solid #f0f3fc;
    box-sizing: border-box;
    > .leftAreaContent {
      width: 100%;
      height: 0;
      flex: 12;
      overflow: auto;
      margin-top: 10px;

      .el-input {
        padding: 5px;
      }
      .el-input__inner {
        height: 30px;
        border-radius: 10px;
        // opacity: 0.5;
      }
      .el-input__icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .custom-tree-node {
        // padding-left: 15px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        img {
          width: 12px;
          height: 12px;
          margin-right: 5px;
        }
        .tb {
          display: none;
        }
        &:hover .tb {
          display: inline;
        }
        i {
          cursor: pointer;
          transition: 0.3s;
          &:hover {
            color: #00ccff;
          }
        }
      }
      .el-tree-node__expand-icon {
        // display: none;
        font-size: 1.3em;
      }

      /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
      &::-webkit-scrollbar {
        width: 6px;
        border-radius: 5px;
        background-color: #f5f5f5ef;
      }

      /*定义滚动条轨道 内阴影+圆角*/
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
        border-radius: 5px;
        background-color: #f5f5f500;
      }

      /*定义滑块 内阴影+圆角*/
      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
        background-color: #dee8fe;
      }

      .el-tree-node__content {
        height: 50px !important;
      }
      .is-current > .el-tree-node__content {
        background-color: #dee8fe !important;
      }
    }
    > .leftAreaBut {
      width: 100%;
      height: 0;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 10px;
      box-sizing: border-box;
      &:hover {
        > .btn {
          // width: 80px;
          // height: 30px;
          // display: flex;
          // justify-content: center;
          // align-items: center;
          // border: 1px solid #5d7bb5;
          // border-radius: 10px;
          // transition: 0.5s;
          // font-size: 12px
          &:hover {
            // transform: scale(1.1);
            cursor: pointer;
            // color: #ffffff;
            // background-color: #5d7bb5;
            // border: 1px solid #ffffff;
          }
        }
        .but {
          // color: red;
          width: 80px;
          opacity: 1;
        }
      }
      > .btn {
        // width: 80px;
        // height: 30px;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // border: 1px solid #5d7bb5;
        // border-radius: 10px;
        // transition: 0.5s;
        // font-size: 12px;
        font-size: 1.5em;
        transition: 0.5s;
      }
      > .but {
        width: 80px;
        height: 30px;
        opacity: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        // border: 1px solid #5d7bb5;
        border-radius: 10px;
        font-size: 12px;
        transition: 0.5s;
        cursor: pointer;
        &:hover {
          color: #ffffff;
          background-color: #5d7bb5;
          border: 1px solid #ffffff;
        }
      }
      > .btnHide {
        display: none;
      }
    }
  }
  > .siteContentArea {
    flex: 10;
    height: 100%;
    overflow: hidden;
    > #siteMenu {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px;
      > .topArea {
        flex: 1;
        width: 100%;
        display: flex;
        background-color: white;
        > .itemMenu {
          flex: 1;
          max-width: 200px;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          &:hover {
            cursor: pointer;
            background-color: #0042e0;
            color: white;
            &::after {
              position: absolute;
              bottom: 0;
              content: "";
              width: 80%;
              height: 3px;
              background-color: #fa8100;
            }
          }
        }
        > .itemMenuActive {
          background-color: #0042e0;
          color: white;
          &::after {
            position: absolute;
            bottom: 0;
            content: "";
            width: 80%;
            height: 3px;
            background-color: #fa8100;
          }
        }
      }
      > .contentArea {
        flex: 12;
        width: 100%;
        // background-color: white;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        > .linkArea {
          display: flex;
          height: auto;
          width: 100%;
          background-color: white;
          > .itemLinkArea {
            height: auto;
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            > div {
              height: 21px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            > div:nth-child(2) {
              &:hover {
                color: #0042e0;
                cursor: pointer;
              }
            }
          }
        }
        > .detailArea {
          flex: 1;
          width: 100%;
          overflow: hidden;
        }
      }
    }
  }
}
</style>