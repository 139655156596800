<template>
  <div id="test">
    <div id="t1" class="aa dd bb cc">1</div>
    <div id="t2" class="aa qq bb cc">2</div>
    <div id="t3" class="aa ww bb cc">3</div>
    <div id="t4" class="aa ee bb cc">4</div>
    <div id="t5" class="aa rr bb cc">5</div>
  </div>
</template>

<script>
import Vue from "vue";

export default {
  data() {
    return {
      list: [
        {class: "dd"},
        {class: "qq"},
        {class: "ww"},
        {class: "ee"},
        {class: "rr"},
      ],
    };
  },
  methods: {

  },
  mounted() {


  },
};
</script>
<style lang="less">
#test {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > .line {
    width: 80%;
    height: 50px;
    display: flex;

    > .title {
      width: 40%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}
</style>