<template>
  <div id="indexArea">
    <el-dialog :title="currentDialogData.title" :visible.sync="dialogVisible" width="60%" :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="itemOption" v-for="(item, index) in currentDialogData.listData" :key="index">
        <div class="leftArea">
          <span v-if="item.isNotNull">*</span>{{ item.name }}:
        </div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" :data="uploadData"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input v-model="item.value" :placeholder="'请输入' + item.name"></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'textarea'">
          <el-input type="textarea" :rows="2" v-model="item.value" :placeholder="'请输入' + item.name"></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select v-model="item.value" :placeholder="'请选择' + item.name" filterable>
            <el-option v-for="item2 in item.valueList" :key="item2.id" :label="item2.name" :value="item2.id">
            </el-option>
          </el-select>
        </div>
        <div class="rightArea" v-if="item.type == 'dateTime'">
          <el-date-picker value-format="yyyy-MM-dd HH:mm:ss" format="yyyy-MM-dd HH:mm:ss" v-model="item.value"
            type="datetime" placeholder="选择日期时间">
          </el-date-picker>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureAddNew(currentDialogData.title)">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div class="indexTopArea">
      <div class="indexTopLeftArea">
        <el-input placeholder="新闻快速搜索" prefix-icon="el-icon-search" v-model="topData.searchData" clearable
          @keyup.enter.native="searchTableData">
        </el-input>
      </div>
      <div class="indexTopRightArea">
        <div class="tipsArea">欢迎登录站群管理系统!</div>

        <div v-if="false" class="noticeArea">
          <img src="../assets/img/ring.png" alt="" />
          <span class="ringNumArea">{{ topData.ringData.value }}</span>
        </div>
        <div class="userArea">
          <div class="userName">{{ userName }}</div>
          <el-dropdown>
            <span class="el-dropdown-link">
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="exit()"> 退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <div class="indexContent" v-if="!showSearchList">
      <div :class="{ indexLeftArea: true, indexLeftAreaActive: isLeftShow }">
        <div class="itemArea" v-for="(item, index) in menuData" :key="index" v-has="item">
          <div :class="{
            itemMenuArea: true,
            itemMenuAreaActive: menuIndex == index,
          }" @click="menuItemClick(item, index)">
            <div class="itemTitleArea">
              <div>
                <img :src="item.icon" alt="" />
              </div>
              <div>{{ item.label }}</div>
            </div>
            <div v-if="false" :class="{
              itemArrowArea: true,
              itemArrowAreaActive: item.isActive,
            }">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <!-- <transition name="fade"> -->
          <div v-if="true" :class="{ childArea: true, childAreaActive: item.isActive }">
            <div :class="{
              itemChildArea: true,
              itemChildAreaShow: item.isActive,
              itemChildAreaActive: item.childIndex == index2,
            }" @click="childItemClick(item, item2, index2)" v-for="(item2, index2) in item.child" :key="index2">
              <div class="itemTitleArea">{{ item2.name }}</div>
              <div class="itemArrowArea">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
          </div>
          <!-- </transition> -->
        </div>
      </div>
      <div class="stretchArea" @click="isLeftShow = !isLeftShow">
        <i v-if="isLeftShow" class="el-icon-d-arrow-right"></i>
        <i v-if="!isLeftShow" class="el-icon-d-arrow-left"></i>
      </div>
      <div class="indexMidArea">
        <router-view v-if="isRouterAlive"></router-view>
      </div>
      <div :class="{ indexRightArea: true, indexRightAreaActive: isRightShow }">
        <div @click="isRightShow = !isRightShow" class="iconArea">
          <i :class="{
            'el-icon-d-arrow-left': true,
            'el-icon-d-arrow-right': isRightShow,
          }"></i>
        </div>
        <div v-if="false" class="shortCutArea">
          <!-- 快捷通道 -->
          <div class="title">快捷通道</div>
          <div class="shortCutContent">
            <div class="itemContent" v-for="(item, index) in shortCutData" :key="index">
              <div>
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div class="calendarArea">
          <!-- 日历表 -->
          <div class="title">日历表</div>
          <div class="calendarContent">
            <el-calendar>
              <template slot="dateCell" slot-scope="{ date, data }">
                <div class="contentArea" @click="dateClick(date, data)">
                  <template v-for="(item, index) in calendarData.monthData">
                    <div class="statusArea" v-if="item.day == data.day" :key="index">
                      <div>
                        <div class="completeArea" v-if="item.status"></div>
                        <div class="uncompleteArea" v-else></div>
                      </div>
                    </div>
                  </template>

                  <div class="dataArea">
                    {{ data.day.split("-").slice(2).join("-") }}
                  </div>
                </div>
              </template>
            </el-calendar>
            <div class="dailyArea">
              <div @click="addWork('本日工作')" class="titleArea">
                <div class="textArea">本日工作</div>
                <div class="addArea">
                  <i class="el-icon-circle-plus-outline"></i>
                </div>
              </div>
              <div class="ContentArea">
                <div class="dailyItem" v-for="(item, index) in calendarData.dayWork" :key="index">
                  <div class="topArea">
                    <div class="iconArea" v-if="!item.status">
                      <img src="../assets/img/running.png" alt="" />
                      未完成
                    </div>
                    <div class="iconArea" v-if="item.status">
                      <img src="../assets/img/complete.png" alt="" />
                      已完成
                    </div>
                    <el-tooltip class="item" effect="dark" :content="item.content" placement="left-start">
                      <div class="realContentArea">{{ item.content }}</div>
                    </el-tooltip>
                    <div class="operateArea">
                      <el-dropdown trigger="click">
                        <i class="el-icon-arrow-down el-icon--right"></i>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item @click.native="
                            updateStatus(item, '本日工作', false)
                            ">未完成
                          </el-dropdown-item>
                          <el-dropdown-item @click.native="updateStatus(item, '本日工作', true)">已完成
                          </el-dropdown-item>
                          <el-dropdown-item @click.native="editContent('编辑本日工作', item)">编辑
                          </el-dropdown-item>
                          <el-dropdown-item @click.native="deleteWork(item, '本日工作')">删除
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                  <div class="bottomArea">
                    {{ item.time }}
                  </div>
                </div>
              </div>
            </div>
            <div class="weekArea">
              <div @click="addWork('本周工作')" class="titleArea">
                <div class="textArea">本周工作</div>
                <div class="addArea">
                  <i class="el-icon-circle-plus-outline"></i>
                </div>
              </div>
              <div class="ContentArea">
                <div class="dailyItem" v-for="(item, index) in calendarData.weekWork" :key="index">
                  <div class="topArea">
                    <div class="iconArea" v-if="!item.status">
                      <img src="../assets/img/running.png" alt="" />
                      未完成
                    </div>
                    <div class="iconArea" v-if="item.status">
                      <img src="../assets/img/complete.png" alt="" />
                      已完成
                    </div>
                    <el-tooltip class="item" effect="dark" :content="item.content" placement="left-start">
                      <div class="realContentArea">{{ item.content }}</div>
                    </el-tooltip>
                    <div class="operateArea">
                      <el-dropdown trigger="click">
                        <i class="el-icon-arrow-down el-icon--right"></i>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item @click.native="
                            updateStatus(item, '本周工作', false)
                            ">未完成
                          </el-dropdown-item>
                          <el-dropdown-item @click.native="updateStatus(item, '本周工作', true)">已完成
                          </el-dropdown-item>
                          <el-dropdown-item @click.native="editContent('编辑本周工作', item)">编辑
                          </el-dropdown-item>
                          <el-dropdown-item @click.native="deleteWork(item, '本周工作')">删除
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                  <div class="bottomArea">
                    {{ item.time }}
                  </div>
                </div>
              </div>
            </div>
            <div class="monthArea">
              <div @click="addWork('本月工作')" class="titleArea">
                <div class="textArea">本月工作</div>
                <div class="addArea">
                  <i class="el-icon-circle-plus-outline"></i>
                </div>
              </div>
              <div class="ContentArea">
                <div class="dailyItem" v-for="(item, index) in calendarData.monthWork" :key="index">
                  <div class="topArea">
                    <div class="iconArea" v-if="!item.status">
                      <img src="../assets/img/running.png" alt="" />
                      未完成
                    </div>
                    <div class="iconArea" v-if="item.status">
                      <img src="../assets/img/complete.png" alt="" />
                      已完成
                    </div>
                    <el-tooltip class="item" effect="dark" :content="item.content" placement="left-start">
                      <div class="realContentArea">{{ item.content }}</div>
                    </el-tooltip>
                    <div class="operateArea">
                      <el-dropdown trigger="click">
                        <i class="el-icon-arrow-down el-icon--right"></i>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item @click.native="
                            updateStatus(item, '本月工作', false)
                            ">未完成
                          </el-dropdown-item>
                          <el-dropdown-item @click.native="updateStatus(item, '本月工作', true)">已完成
                          </el-dropdown-item>
                          <el-dropdown-item @click.native="editContent('编辑本月工作', item)">编辑
                          </el-dropdown-item>
                          <el-dropdown-item @click.native="deleteWork(item, '本月工作')">删除
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </div>
                  </div>
                  <div class="bottomArea">
                    {{ item.time }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="indexContent" v-else>
      <!-- 全局搜索文档列表 -->
      <div class="ll">
        <el-button type="danger" plain style="margin-bottom: 20px" @click="showSearchList = false">取消搜索
        </el-button>
        <el-table :data="tableData" border style="width: 100%" height="60vh">
          <el-table-column type="index" width="50" label="序号">
          </el-table-column>
          <el-table-column prop="title" label="标题" width="">
            <template slot-scope="scope">
              <div @click="gotoDetails(scope.row.url)" style="cursor: pointer">
                {{ scope.row.title }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="site" label="站点" width="220">
          </el-table-column>
          <el-table-column prop="column" label="栏目" width="220">
          </el-table-column>
          <el-table-column prop="type" label="文档类型" width="180">
          </el-table-column>
          <el-table-column prop="status" label="发布状态" width="180">
          </el-table-column>
          <el-table-column prop="time" label="发布时间" width="180">
          </el-table-column>
          <el-table-column prop="createUser" label="创建人" width="180">
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page.sync="searchPageData.currentPage" :page-sizes="[16, 32, 48, 64]"
            :page-size="searchPageData.pageSize" layout="total,sizes,prev, pager, next, jumper"
            :total="searchPageData.total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import has from "../directives/has1.js";
import router from "@/router";

export default {
  // inject: ["reload"],
  directives: {
    has: has,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      showSearchList: false, // 是否展示全局搜索的文档列表
      tableData: [],

      searchDocList: [], // 全局文档搜索列表展示表格
      searchPageData: {
        // 全局文档搜索列表的分页数据
        currentPage: 1, // 当前页数
        pageSize: 16, // 每页条数
        total: 0, // 总条目数
      },
      userName: localStorage.getItem("userName"),
      searchList: [],
      currentDate: moment(new Date()).format("YYYY-MM-DD"),
      //弹出窗标志
      dialogVisible: false,
      //currentDialog当前dialog中数据
      currentDialogData: {
        title: "",
        listData: [
          {
            id: "",
            type: "textarea",
            name: "工作内容",
            value: "",
            status: false,
            isNotNull: true,
          },
        ],
      },
      //左侧抽屉是否显示
      isLeftShow: true, // 默认左侧菜单关闭状态
      //右侧抽屉是否展示
      isRightShow: false,
      isRouterAlive: true,
      // 顶栏数据
      topData: {
        userData: {
          icon: "",
          id: "", //用户id
          name: "", //用户名称
          password: "", //用户密码 用于修改密码
        },
        searchData: "",
        ringData: {
          // 提醒数据
          value: 3,
        },
      },
      //菜单数据
      menuIndex: -1,
      menuData: [
        {
          id: "",
          label: "站点管理",
          page: "menuList",
          type: "authorityData",
          icon: require("../assets/img/站点管理.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [
            // {
            //   id: "111",
            //   name: "站点1",
            // },
            // {
            //   id: "222",
            //   name: "站点2",
            // },
            // {
            //   id: "333",
            //   name: "站点3",
            // },
          ],
        },
        {
          id: "",
          label: "模板管理",
          page: "menuList",
          type: "authorityData",
          icon: require("../assets/img/模块管理.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [
            // {
            //   id: "111",
            //   name: "站点模板",
            //   icon: "",
            // },
            // {
            //   id: "222",
            //   name: "栏目模板",
            //   icon: "",
            // },
            // {
            //   id: "333",
            //   name: "文档模板",
            //   icon: "",
            // },
          ],
        },
        {
          id: "",
          label: "组件管理",
          page: "menuList",
          type: "authorityData",
          icon: require("../assets/img/组件管理.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [],
        },
        {
          id: "",
          label: "任务管理",
          page: "menuList",
          type: "authorityData",
          icon: require("../assets/img/任务管理.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [],
        },
        {
          id: "",
          label: "VR全景",
          page: "menuList",
          type: "authorityData",
          icon: require("../assets/img/VR全景配置.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [],
        },
        {
          id: "",
          label: "直播管理",
          page: "menuList",
          type: "authorityData",
          icon: require("../assets/img/直播配置.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [],
        },
        {
          id: "",
          label: "图片资源",
          page: "menuList",
          type: "authorityData",
          icon: require("../assets/img/图片资源管理.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [],
        },
        {
          id: "",
          label: "视频资源",
          page: "menuList",
          type: "authorityData",
          icon: require("../assets/img/视频资源管理.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [],
        }, {
          id: "",
          label: "文件资源",
          page: "menuList",
          type: "authorityData",
          icon: require("../assets/img/视频资源管理.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [],
        },
        {
          id: "",
          label: "资源库",
          page: "menuList",
          type: "authorityData",
          icon: require("../assets/img/视频资源管理.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [],
        },
        {
          id: "",
          label: "协同管理",
          type: "authorityData",
          page: "menuList",
          icon: require("../assets/img/协同服务.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [],
        },
        {
          id: "",
          label: "角色管理",
          type: "authorityData",
          page: "menuList",
          icon: require("../assets/img/角色管理.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [],
        },
        {
          id: "",
          label: "权限管理",
          type: "authorityData",
          page: "menuList",
          icon: require("../assets/img/权限管理.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [],
        },
        // {
        //   id: "",
        //   label: "运维审计",
        //   type: "authorityData",
        //   page: "menuList",
        //   icon: require("../assets/img/运维审计.png"),
        //   isActive: false, //是否展开
        //   childIndex: -1,
        //   child: [],
        // },
        {
          id: "",
          label: "人员管理",
          type: "authorityData",
          page: "menuList",
          icon: require("../assets/img/权限管理.png"),
          isActive: false, //是否展开
          childIndex: -1,
          child: [],
        },
      ],
      shortCutData: [
        // 快速通道数据
        {
          id: "",
          name: "栏目1",
        },
        {
          id: "",
          name: "栏目2",
        },
        {
          id: "",
          name: "栏目3",
        },
        {
          id: "",
          name: "栏目4",
        },
        {
          id: "",
          name: "栏目5",
        },
        {
          id: "",
          name: "栏目6",
        },
      ],
      // 日历数据
      calendarData: {
        monthData: [
          {
            day: "2022-04-18",
            status: false,
          },
          {
            day: "2022-04-17",
            status: true,
          },
        ],
        //本日工作
        dayWork: [
          {
            id: "",
            time: "2022-04-01",
            content: "本日工作1",
            status: false, //完成状态
          },
          {
            id: "",
            time: "2022-04-01",
            content: "本日工作2",
            status: true, //完成状态
          },
          {
            id: "",
            time: "2022-04-01",
            content: "本日工作3",
            status: true, //完成状态
          },
        ],
        //本周工作
        weekWork: [
          {
            id: "",
            time: "2022-04-01",
            content: "本周工作1",
            status: false, //完成状态
          },
          {
            id: "",
            time: "2022-04-01",
            content: "本周工作2",
            status: true, //完成状态
          },
          {
            id: "",
            time: "2022-04-01",
            content: "本周工作3",
            status: true, //完成状态
          },
        ],
        //本月工作
        monthWork: [
          {
            id: "",
            time: "2022-04-01",
            content: "本月工作1",
            status: false, //完成状态
          },
          {
            id: "",
            time: "2022-04-01",
            content:
              "本月工作2本月工作2本月工作2本月工作2本月工作2本月工作2本月工作2本月工作2本月工作2本月工作2",
            status: true, //完成状态
          },
          {
            id: "",
            time: "2022-04-01",
            content: "本月工作3",
            status: true, //完成状态
          },
        ],
      },
      cacheData: {
        //缓存界面操作数据  例如上次点击的菜单名称 用来切换选中菜单
        lastMenu: {
          siteName: "",
        },
      },
      dialogData: {
        flag: false,
        name: "",
      },
    };
  },
  // watch: {
  //   menuData: {
  //     handler(newVal, oldVal) {
  //       //执行代码
  //       if (this.menuIndex == 0 && this.menuData[0].childIndex!=-1) {
  //         //判断是第一项中的索引
  //         //console.log("索引改变",this.menuData[0].childIndex);
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    /**
     * 快捷搜索相关
     * @param {*} queryString
     * @param {*} cb
     */
    querySearchAsync(queryString, cb) {
      this.getSearchDocList((data) => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(data);
        }, 1000);
      });
    },
    /**
     * 处理回调
     * @param {*} item
     */
    handleSelect(item) {
      //console.log(item);
      this.$request(
        "post",
        "/doc/directPreview",
        {
          id: item.id,
        },
        (data) => {
          window.open(data.result.previewUrl, "_blank");
        }
      );
    },
    // 退出登录
    exit() {
      localStorage.removeItem("userId");
      localStorage.removeItem("userName");
      router.push("/login")
      this.$message({
        type: "success",
        message: "已退出登录!",
      });
    },
    //删除日程
    deleteWork(item, type) {
      switch (type) {
        case "本日工作":
          this.$request(
            "post",
            "/schedule/deleteWork",
            {
              userId: "1",
              id: item.id,
            },
            (data) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.dialogVisible = false;
              this.getDayWorkList();
            }
          );
          break;
        case "本周工作":
          this.$request(
            "post",
            "/schedule/deleteWork",
            {
              userId: "1",
              id: item.id,
            },
            (data) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.dialogVisible = false;
              this.getWeekWorkList();
            }
          );
          break;
        case "本月工作":
          this.$request(
            "post",
            "/schedule/deleteWork",
            {
              userId: "1",
              id: item.id,
            },
            (data) => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.dialogVisible = false;
              this.getMonthWorkList();
            }
          );
          break;

        default:
          break;
      }
    },
    //更新状态
    updateStatus(item, type, status) {
      switch (type) {
        case "本日工作":
          // 更新本日
          this.$request(
            "post",
            "/schedule/updateWork",
            {
              userId: "1",
              content: item.content,
              id: item.id,
              status: status,
            },
            (data) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.dialogVisible = false;
              this.getDayWorkList();
            }
          );
          break;
        case "本周工作":
          // 更新本周
          this.$request(
            "post",
            "/schedule/updateWork",
            {
              userId: "1",
              content: item.content,
              id: item.id,
              status: status,
            },
            (data) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.dialogVisible = false;
              this.getWeekWorkList();
            }
          );
          break;
        case "本月工作":
          // 更新本月
          this.$request(
            "post",
            "/schedule/updateWork",
            {
              userId: "1",
              content: item.content,
              id: item.id,
              status: status,
            },
            (data) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.dialogVisible = false;
              this.getMonthWorkList();
            }
          );
          break;

        default:
          break;
      }
    },
    //确定新增
    sureAddNew(title) {
      switch (title) {
        case "新增本日工作":
          if (this.currentDialogData.listData[0].value == "") {
            this.$message.error("工作内容不能为空!");
            return;
          }
          if (this.currentDialogData.listData[0].value.length > 100) {
            this.$message.error("工作内容过长,请重新重新填写(最大长度100)!");
            return;
          }
          this.$request(
            "post",
            "/schedule/addDayWork",
            {
              userId: "1",
              currentDate: this.currentDate,
              content: this.currentDialogData.listData[0].value,
            },
            (data) => {
              this.$message({
                type: "success",
                message: "新增成功!",
              });
              this.dialogVisible = false;
              this.getDayWorkList();
            }
          );
          break;
        case "新增本周工作":
          if (this.currentDialogData.listData[0].value == "") {
            this.$message.error("工作内容不能为空!");
            return;
          }
          if (this.currentDialogData.listData[0].value.length > 100) {
            this.$message.error("工作内容过长,请重新重新填写(最大长度100)!");
            return;
          }
          this.$request(
            "post",
            "/schedule/addWeekWork",
            {
              userId: "1",
              currentDate: this.currentDate,
              content: this.currentDialogData.listData[0].value,
            },
            (data) => {
              this.$message({
                type: "success",
                message: "新增成功!",
              });
              this.dialogVisible = false;
              this.getWeekWorkList();
            }
          );
          break;
        case "新增本月工作":
          if (this.currentDialogData.listData[0].value == "") {
            this.$message.error("工作内容不能为空!");
            return;
          }
          if (this.currentDialogData.listData[0].value.length > 100) {
            this.$message.error("工作内容过长,请重新重新填写(最大长度100)!");
            return;
          }
          this.$request(
            "post",
            "/schedule/addMonthWork",
            {
              userId: "1",
              currentDate: this.currentDate,
              content: this.currentDialogData.listData[0].value,
            },
            (data) => {
              this.$message({
                type: "success",
                message: "新增成功!",
              });
              this.dialogVisible = false;
              this.getMonthWorkList();
            }
          );
          break;
        case "编辑本日工作":
          if (this.currentDialogData.listData[0].value == "") {
            this.$message.error("工作内容不能为空!");
            return;
          }
          if (this.currentDialogData.listData[0].value.length > 100) {
            this.$message.error("工作内容过长,请重新重新填写(最大长度100)!");
            return;
          }
          this.$request(
            "post",
            "/schedule/updateWork",
            {
              userId: "1",
              content: this.currentDialogData.listData[0].value,
              id: this.currentDialogData.listData[0].id,
              status: this.currentDialogData.listData[0].status,
            },
            (data) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.dialogVisible = false;
              this.getDayWorkList();
            }
          );
          break;
        case "编辑本周工作":
          if (this.currentDialogData.listData[0].value == "") {
            this.$message.error("工作内容不能为空!");
            return;
          }
          if (this.currentDialogData.listData[0].value.length > 100) {
            this.$message.error("工作内容过长,请重新重新填写(最大长度100)!");
            return;
          }
          this.$request(
            "post",
            "/schedule/updateWork",
            {
              userId: "1",
              content: this.currentDialogData.listData[0].value,
              id: this.currentDialogData.listData[0].id,
              status: this.currentDialogData.listData[0].status,
            },
            (data) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.dialogVisible = false;
              this.getWeekWorkList();
            }
          );
          break;
        case "编辑本月工作":
          if (this.currentDialogData.listData[0].value == "") {
            this.$message.error("工作内容不能为空!");
            return;
          }
          if (this.currentDialogData.listData[0].value.length > 100) {
            this.$message.error("工作内容过长,请重新重新填写(最大长度100)!");
            return;
          }
          this.$request(
            "post",
            "/schedule/updateWork",
            {
              userId: "1",
              content: this.currentDialogData.listData[0].value,
              id: this.currentDialogData.listData[0].id,
              status: this.currentDialogData.listData[0].status,
            },
            (data) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
              this.dialogVisible = false;
              this.getMonthWorkList();
            }
          );
          break;

        default:
          break;
      }
    },
    //新增工作
    addWork(type) {
      this.dialogVisible = true;
      this.currentDialogData.title = "新增" + type;
    },
    editContent(type, item) {
      this.dialogVisible = true;
      this.currentDialogData.title = type;
      this.currentDialogData.listData[0].value = item.content;
      this.currentDialogData.listData[0].id = item.id;
      this.currentDialogData.listData[0].status = item.status;
    },
    //
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    //时间点击
    dateClick(date, data) {
      this.currentDate = data.day;
      this.getMonthStatus();
      this.getDayWorkList();
      this.getWeekWorkList();
      this.getMonthWorkList();
    },
    /***
     * 点击父级菜单
     */
    menuItemClick(item, index) {
      // 存储父级菜单的索引
      localStorage.setItem("indexPageIndex", index);
      this.clearIndex();
      switch (item.label) {
        case "站点管理":
          this.$router.push({
            path: "/siteManage",
          });
          this.reload();
          break;
        case "模板管理":
          this.$router.push({
            path: "/templateManage",
          });
          this.reload();
          break;
        case "组件管理":
          this.$router.push({
            path: "/assemblyManage",
          });
          this.reload();
          break;
        case "任务管理":
          this.$router.push({
            path: "/taskManage",
          });
          break;
        case "VR全景":
          this.$router.push({
            path: "/VRInfo",
          });
          this.reload();
          break;
        case "直播管理":
          this.$router.push({
            path: "/liveVideo",
          });
          this.reload();
          break;
        case "图片资源":
          this.$router.push({
            path: "/imageResource",
          });
          this.reload();
          break;
        case "视频资源":
          this.$router.push({
            path: "/videoResource",
          });
          this.reload();
          break;
        case "文件资源":
          this.$router.push({
            path: "/documentResource",
          });
          this.reload();
          break;
        case "资源库":
          this.$router.push({
            path: "/resourceK",
          });
          this.reload();
          break;
        case "协同管理":
          this.$router.push({
            path: "/coordinationManage",
          });
          this.reload();
          break;
        case "权限管理":
          this.$router.push({
            path: "/authorityAllocate",
          });
          this.reload();
          break;
        case "角色管理":
          this.$router.push({
            path: "/roleManage",
          });
          this.reload();
          break;
        case "运维审计":
          this.$router.push({
            path: "/audit",
          });
          this.reload();
          break;
        case "人员管理":
          this.$router.push({
            path: "/PersonneManagement",
          });
          this.reload();
          break;
        default:
          break;
      }
      setTimeout(() => {
        item.isActive = !item.isActive;
        this.menuIndex = index;
      }, 50);
    },

    /***
     * 点击子级菜单
     */
    childItemClick(item, item2, index2) {
      this.clearIndex();
      item.childIndex = index2;
      item2.isActive = !item2.isActive;
      switch (item2.name) {
        case "新建站点":
          this.addSite();
          break;

        default:
          //console.log("index站点", item2.id);
          this.$router.push({
            path: "/siteManage/siteInfo",
            query: { id: item2.id },
          });
          // this.reload();
          break;
      }
    },

    /**
     * 清除子级菜单索引
     */
    clearIndex() {
      for (let index = 0; index < this.menuData.length; index++) {
        const element = this.menuData[index];
        element.childIndex = -1;
      }
    },
    // 用作路由刷新
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    getMonthStatus() {
      this.$request(
        "post",
        "/schedule/getMonthStatus",
        {
          userId: "1",
          currentDate: this.currentDate,
        },
        (data) => {
          this.calendarData.monthData = data.result;
        }
      );
    },
    getDayWorkList() {
      this.$request(
        "post",
        "/schedule/getDayWorkList",
        {
          userId: "1",
          currentDate: this.currentDate,
        },
        (data) => {
          this.calendarData.dayWork = data.result;
        }
      );
      this.getMonthStatus();
    },
    getWeekWorkList() {
      this.$request(
        "post",
        "/schedule/getWeekWorkList",
        {
          userId: "1",
          currentDate: this.currentDate,
        },
        (data) => {
          this.calendarData.weekWork = data.result;
        }
      );
    },
    getMonthWorkList() {
      this.$request(
        "post",
        "/schedule/getMonthWorkList",
        {
          userId: "1",
          currentDate: this.currentDate,
        },
        (data) => {
          this.calendarData.monthWork = data.result;
        }
      );
    },
    getSearchDocList(tel) {
      this.$request(
        "post",
        "/doc/getSearchDocList",
        {
          userId: "1",
          keyword: this.topData.searchData,
        },
        (data) => {
          tel(data.result);
        }
      );
    },
    getCurrentAuthorityData() {
      this.$request(
        "post",
        "/authority/getAuthority",
        {
          id: "1",
          key: ["menuList"],
          relationId: null,
          type: "0",
        },
        (data) => {
          if (
            data.result.menuList.checkList == null ||
            data.result.menuList.checkList.length == 0
          ) {
            //console.log("获取首页权限", data.result.menuList);
            this.$message.error("越权操作!");
            setTimeout(() => {
              localStorage.removeItem("userId");
              localStorage.removeItem("userName");
              router.push("/login")
              // this.$router.push("/test");
            }, 100);
            return;
          }
          localStorage.setItem("menuList", JSON.stringify(data.result));
        }
      );
    },
    searchTableData() {
      this.showSearchList = true;
      let requestData = {
        docName: this.topData.searchData,
        userId: "1",
        pageSize: this.searchPageData.pageSize,
        currentPage: this.searchPageData.currentPage,
      };
      this.$request(
        "post",
        "/doc/getAllDocListByGlobal",
        requestData,
        (data) => {
          this.tableData = data.result.tableData;
          this.searchPageData.total = data.result.total;
        }
      );
    },
    handleSizeChange(val) {
      this.searchPageData.pageSize = val;
      this.searchTableData();
    },
    //选择页的变化
    handleCurrentChange(val) {
      this.searchPageData.currentPage = val;
      this.searchTableData();
    },
    gotoDetails(url) {
      if (url != "") {
        // this.showSearchList = false;
        window.open(url, "_blank");
      } else {
        this.$message({
          type: "success",
          message: "当前文档暂未发布!",
        });
      }
    },
  },
  mounted() {
    // this.getCurrentAuthorityData();
    //加载搜索数据
    // this.searchList = this.loadAll();
    // this.getSearchDocList();
    if (localStorage.getItem("indexPageIndex") != null) {
      this.menuIndex = localStorage.getItem("indexPageIndex");
    } else {
      this.menuIndex = 0;
    }
    this.getDayWorkList();
    this.getWeekWorkList();
    this.getMonthWorkList();
    this.getMonthStatus();
  },
  beforeCreate() {
    localStorage.removeItem("menuList");
  },
  created() {
    // //console.log("111");
    this.getCurrentAuthorityData();
    // let data = {
    //   menuList: {
    //     name: "左侧菜单",
    //     checkList: [
    //       "站点管理",
    //       "模板管理",
    //       "组件管理",
    //       "任务管理",
    //       "VR全景",
    //       "直播管理",
    //       "图片资源",
    //       "视频资源",
    //       "协同管理",
    //       "角色管理",
    //       "权限管理",
    //     ],
    //   },
    // };
    // localStorage.setItem("menuList", JSON.stringify(data));
  },
};
</script>

<style lang="less">
#indexArea {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  >.el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .el-dialog__body {
        height: auto;
        width: 100%;

        >.itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;

          >.leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            >span {
              color: #ff0000;
              font-size: 15px;
              width: 8px;
            }
          }

          >.rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .el-select {
              width: 100%;
            }

            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }

      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }

  >.indexTopArea {
    flex: 1;
    width: 100%;
    background-image: url("../assets/img/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;

    >.indexTopLeftArea {
      margin-left: 20px;

      .el-input__inner {
        height: 30px;
        border-radius: 10px;
        opacity: 0.5;
      }

      .el-input__icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    >.indexTopRightArea {
      display: flex;
      justify-content: center;
      align-items: center;

      >.tipsArea {
        color: white;
      }

      >.noticeArea {
        position: relative;

        >.ringNumArea {
          float: left;
          position: absolute;
          top: 0;
          right: 0;
          background-color: red;
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          color: white;
          opacity: 0.7;
        }
      }

      >.userArea {
        width: 35px;
        height: 35px;
        background-color: white;
        border-radius: 50%;
        margin: 0px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .userName {
          font-size: 0.1%;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          /*要显示文字的宽度*/
          text-overflow: ellipsis;
          /*让截断的文字显示为点点。还有一个值是clip意截断不显示点点*/
          white-space: nowrap;
          /*让文字不换行*/
          overflow: hidden;
        }

        >.el-dropdown {
          width: 100%;
          height: 100%;
          display: flex;

          .el-dropdown-link {
            width: 100%;
            height: 100%;

            .el-icon-arrow-down {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  >.indexContent {
    flex: 10;
    width: 100%;
    display: flex;
    overflow: hidden; //限制总体的高度 如果没有 会被子元素撑开
    position: relative;

    >.indexLeftArea {
      flex: 2;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow-y: auto;
      transition: 0.5s;
      opacity: 1;
      padding-left: 20px;

      >.itemArea {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;

        // overflow: hidden;
        >.itemMenuArea {
          height: 50px;
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          overflow: hidden;
          transition: 0.5s;

          &:hover {
            cursor: pointer;
            background-color: #dee8fe;
          }

          >.itemTitleArea {
            flex: 7;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow-x: auto;

            >div:nth-child(1) {
              margin: 0 10px;
            }
          }

          >.itemArrowArea {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            >.el-icon-arrow-right {
              transition: 0.5s;
            }
          }

          >.itemArrowAreaActive {
            >.el-icon-arrow-right {
              // color: #00ff00;
              transform: rotate(90deg);
            }
          }
        }

        >.itemMenuAreaActive {
          background-color: #dee8fe;
        }

        >.childArea {
          height: auto;
          width: 100%;
          display: flex;
          flex-direction: column;

          >.itemChildArea {
            height: 0;
            opacity: 0;
            overflow: hidden;
            width: 100%;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            position: relative;
            transition: 0.5s;

            >.itemTitleArea {
              flex: 7;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              // overflow-x: auto;
              // margin: 0 10px;
            }

            >.itemArrowArea {
              flex: 1;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &:hover::before {
              content: "";
              height: 100%;
              width: 3px;
              background-color: #0042e0;
              position: absolute;
              left: 3px;
            }

            &:hover {
              cursor: pointer;
              background-color: #dee8fe;
            }
          }

          >.itemChildAreaShow {
            transition: 0.5s;
            height: 50px;
            opacity: 1;
          }

          >.itemChildAreaActive {
            background-color: #dee8fe;

            &::before {
              content: "";
              height: 100%;
              width: 3px;
              background-color: #0042e0;
              position: absolute;
              left: 3px;
            }
          }
        }


      }
    }

    >.indexLeftAreaActive {
      opacity: 0;
      flex: 0;
      transition: 0.5s;
    }

    >.stretchArea {
      position: fixed;
      left: 0%;
      top: 8.5%;
      width: 20px;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 1px solid #0042e0;
      // border-radius: 50%;
      // margin-top: 10px;
      // border-right: 1px solid black;
      z-index: 9;
      // font-size: 1.2em;
      cursor: pointer;
      transition: 0.5s;

      >.el-icon-d-arrow-right::before {
        width: 16px;
        height: 16px;
      }

      &:hover {
        // transform: rotate(-180deg);
        color: #0042e0;
      }
    }

    >.indexMidArea {
      flex: 10;
      height: 100%;
      background-color: #f9fbff;
      overflow: hidden;
    }

    >.indexRightArea {
      // float: right;
      position: absolute;
      width: 500px;
      height: 100%;
      right: 0;
      top: 0;
      transform: translateX(100%);
      transition: 0.5s;
      display: flex;
      flex-direction: column;
      background-color: #f9fbff;
      padding: 20px;
      z-index: 9;

      >.iconArea {
        display: flex;
        position: absolute;
        width: 30px;
        height: 30px;
        border: 1px solid #dee8fe;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transform: translateX(-50px);
        cursor: pointer;
        transition: 0.5s;

        &:hover {
          background-color: #dee8fe;
          border: 1px solid #dee8fe;
          //  transform:translateX(-30px) rotateZ(180deg);
        }
      }

      >.shortCutArea {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
        overflow: hidden;

        >.title {
          flex: 1;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.1em;
        }

        >.shortCutContent {
          flex: 5;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          overflow-y: auto;
          justify-content: center;
          align-content: space-around;

          >.itemContent {
            width: 30%;
            height: 40%;
            background-color: #eeeeee;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 2px;

            &:hover {
              cursor: pointer;
              background-color: #dee8fe;
            }
          }
        }
      }

      >.calendarArea {
        flex: 3;
        width: 100%;
        display: flex;
        flex-direction: column;
        background-color: white;
        margin-top: 30px;
        overflow: hidden;

        >.title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.1em;
        }

        >.calendarContent {
          width: 100%;
          height: 100%;
          overflow: auto;
          padding: 5px;
          /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/

          &::-webkit-scrollbar {
            width: 6px;
            border-radius: 5px;
            background-color: #f3f3f3ef;
          }

          /*定义滚动条轨道 内阴影+圆角*/

          &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0);
            border-radius: 5px;
            background-color: #f5f5f500;
          }

          /*定义滑块 内阴影+圆角*/

          &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
            background-color: #ededed;
          }

          .el-calendar-table .el-calendar-day {
            height: auto;
            display: flex;
            justify-content: center;
            transition: 0.5s;
          }

          .el-calendar__button-group {
            display: none;
          }

          .el-calendar-day {
            width: 57px;
            height: 35px;
            padding: 0;

            >.contentArea {
              width: 57px;
              height: 35px;
              position: relative;
              display: flex;

              justify-content: center;
              align-items: center;

              >.statusArea {
                position: absolute;
                left: 0;
                top: 0;

                .completeArea {
                  width: 5px;
                  height: 5px;
                  border-radius: 50%;
                  background-color: rgb(0, 255, 0);
                }

                .uncompleteArea {
                  width: 5px;
                  height: 5px;
                  border-radius: 50%;
                  background-color: rgb(254, 110, 57);
                }
              }
            }
          }

          .el-calendar-day:hover {
            color: rgb(49, 160, 251);
            // background-color: rgb(49, 160, 251);
          }

          .is-selected {
            color: white;
            background-color: rgb(138, 202, 255);
          }

          .is-today {
            color: white;
            background-color: rgb(49, 160, 251);
          }

          >.dailyArea,
          .weekArea,
          .monthArea {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: column;
            // background-color: #f9f9f9;
            margin: 10px 0px;
            border-radius: 10px;

            // box-shadow: 0 0 5px 1px rgb(212, 212, 212);
            >.titleArea {
              height: auto;
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              position: relative;
              color: rgb(49, 160, 251);
              transition: 0.5s;
              box-sizing: border-box;
              border: 1px solid transparent;

              &:hover {
                cursor: pointer;
                border-bottom: 1px solid rgb(49, 160, 251);
              }

              >.addArea {
                cursor: pointer;
              }

              // &::before{
              //   position: absolute;
              //   top: 2px;
              //   content: '';
              //   width: 3px;
              //   height: 18px;
              //   background-color: #ffbf48;
              //   z-index: 9;
              // }
            }

            >.ContentArea {
              width: 100%;
              height: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              overflow: hidden;

              >.dailyItem {
                width: 90%;
                height: 60px;
                display: flex;
                justify-content: center;
                flex-direction: column;
                margin: 5px;
                border: 1.5px solid rgb(224, 224, 224);
                border-radius: 2px;
                background-color: white;

                >.topArea {
                  width: 100%;
                  height: auto;
                  display: flex;
                  justify-content: space-between;
                  padding: 0 10px;

                  >.iconArea {
                    >img {
                      width: 15px;
                      height: 15px;
                    }

                    display: flex;
                    justify-content: center;
                    align-items: center;
                  }

                  >.operateArea {
                    font-size: 1.2em;
                    cursor: pointer;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 19px;
                    height: 19px;
                    border-radius: 50%;
                    transition: 0.5s;

                    &:hover {
                      // transform: rotate(-180deg);
                      background-color: rgb(234, 234, 234);
                    }

                    .el-dropdown {
                      width: 19px;
                      height: 19px;
                      display: flex;
                      justify-content: center;
                      align-items: center;

                      .el-icon--right {
                        margin: 0;
                      }
                    }
                  }

                  .realContentArea {
                    width: 200px;
                    overflow: hidden;
                    word-break: keep-all;
                    /* 不换行 */
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    /*省略 */
                  }
                }

                >.bottomArea {
                  padding: 0 10px;
                }
              }
            }
          }
        }
      }
    }

    >.indexRightAreaActive {
      transform: translateX(0%);

      >.iconArea {
        &:hover {
          transform: translateX(-50px) rotateZ(180deg);
        }
      }
    }

    >.ll {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 1%;
      box-sizing: border-box;

      >.block {
        width: 100%;
        height: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
</style>
