<template>
  <div id="audit">
    <el-dialog
      :title="currentDialogData.title"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div
        class="itemOption"
        v-for="(item, index) in currentDialogData.listData"
        :key="index"
      >
        <div class="leftArea">{{ item.name }}:</div>
        <div class="rightArea labelArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input
            v-model="item.value"
            :placeholder="'请输入' + item.name"
          ></el-input>
          <div class="dw">单位MB</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureRelease()">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div class="contentArea">
      <div class="optionArea">
        <div class="itemOption" v-for="(item, index) in listData" :key="index">
          <div class="leftArea">{{ item.name }}:</div>
          <div class="rightArea" v-if="item.type == 'pic'">
            <el-upload
              class="avatar-uploader"
              :action="uploadUrl"
              :show-file-list="false"
              :data="uploadData"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <img
                style="width: 100px; height: 50px"
                :src="item.value"
                alt=""
              />
            </el-upload>
          </div>
          <div class="rightArea" v-if="item.type == 'label'">
            {{ item.value }}
          </div>
          <div class="rightArea" v-if="item.type == 'input'">
            <el-input v-model="item.value" placeholder="请输入内容"></el-input>
          </div>
          <div class="rightArea" v-if="item.type == 'textarea'">
            <el-input
              type="textarea"
              :rows="2"
              v-model="item.value"
              placeholder="请输入内容"
            ></el-input>
          </div>
          <div class="rightArea" v-if="item.type == 'dateTime'">
            <el-date-picker
              v-model="item.value"
              type="datetime"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
          <div class="rightArea" v-if="item.type == 'twodateTime'">
            <el-date-picker
              v-model="item.value1"
              type="datetime"
              placeholder="选择开始日期"
            >
            </el-date-picker>
            <el-date-picker
              v-model="item.value2"
              type="datetime"
              placeholder="选择结束日期"
            >
            </el-date-picker>
          </div>
          <div class="rightArea" v-if="item.type == 'select'">
            <el-select
              clearable
              value-key="id"
              v-model="item.value"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item2 in item.valueList"
                :key="item2.id"
                :label="item2.name"
                :value="item2"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="searchArea">
          <el-button
            type="primary"
            @click="query"
            icon="el-icon-search"
            size="mini"
            >查询</el-button
          >
          <el-button
            type="primary"
            @click="backAudit"
            icon="el-icon-upload"
            size="mini"
            >手动备份</el-button
          >
          <el-button
            type="primary"
            @click="settingAudit"
            icon="el-icon-setting"
            size="mini"
            >设置</el-button
          >
        </div>
      </div>

      <el-table
        highlight-current-row
        ref="multipleTable"
        :data="
          tableData.filter(
            (data) =>
              !search || data.name.toLowerCase().includes(search.toLowerCase())
          )
        "
        tooltip-effect="dark"
        border
        style="width: 100%; height: 100%; overflow: auto"
        @selection-change="handleSelectionChange"
        @row-click="rowclick"
      >
        <el-table-column prop="menu" label="菜单名称" width="120">
        </el-table-column>
        <el-table-column prop="eventName" label="事件名称" width="100">
        </el-table-column>
        <el-table-column prop="eventType" label="事件分类" width="120">
        </el-table-column>
        <el-table-column prop="visitType" label="访问类型" width="100">
        </el-table-column>
        <el-table-column prop="severityLevel" label="严重等级" width="100">
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="ip"
          label="客户端ip"
          width="120"
        >
        </el-table-column>
        <el-table-column
          prop="content"
          :show-overflow-tooltip="true"
          label="操作内容"
        >
        </el-table-column>
        <el-table-column prop="isException" label="异常访问" width="100">
        </el-table-column>
        <el-table-column prop="requestResult" label="访问结果" width="100">
        </el-table-column>
        <el-table-column prop="userId" label="操作用户" width="100">
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          :default-sort="defaultSort"
          @sort-change="sortChange"
          prop="time"
          label="操作时间"
          width="120"
        >
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="pageSize"
        layout="total,sizes,prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import moment from "moment";
import hasDirective from "../../directives/has1.js";
export default {
  name: "in-coder",
  directives: {
    has: hasDirective,
  },
  props: {
    // 外部传入的内容，用于实现双向绑定
    value: String,
    // 外部传入的语法类型
    language: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      //筛选条件

      //每一行的按钮
      rowBtn: [],
      //表格右上角按钮数据
      tableMenuBtn: [
        {
          type: "authorityData",
          label: "删除",
          page: "imageResource",
          class: "el-icon-delete itemBtn",
          method: "delete",
        },
      ],
      //分页数据
      pageSize: 10,
      total: 10,
      currentPage: 1,
      //分页数据
      pageSize: 10,
      //当前行
      currentRow: {},
      //上传图片数据
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      //弹出窗标志
      dialogVisible: false,
      //currentDialog当前dialog中数据
      currentDialogData: {
        title: "日志存储设置",
        listData: [
          {
            type: "label",
            name: "当前容量",
            value: "",
          },
          {
            type: "input",
            name: "容量上限",
            value: "",
          },
        ],
      },
      search: "",
      tableData: [],
      uploadUrl: this.$baseurl + "/document/uploadImageByMultipartFile",
      uploadData: {
        systemName: "cluster_register",
        path: "",
        title: "上传文档",
      },
      listData: [
        {
          type: "input",
          name: "菜单名称",
          value: "",
        },
        {
          type: "input",
          name: "事件名称",
          value: "",
        },
        {
          type: "input",
          name: "操作人员",
          value: "",
        },
        {
          type: "select",
          name: "事件分类",
          value: "",
          valueList: [
            {
              id: "1",
              name: "系统级",
            },
            {
              id: "2",
              name: "业务级",
            },
          ],
        },
        {
          type: "select",
          name: "访问类型",
          value: "",
          valueList: [
            {
              id: "1",
              name: "越权访问",
            },
            {
              id: "2",
              name: "正常访问",
            },
            {
              id: "3",
              name: "访问IP地址异常",
            },
          ],
        },
        {
          type: "select",
          name: "是否异常访问",
          value: "",
          valueList: [
            {
              id: "1",
              name: "是",
            },
            {
              id: "2",

              name: "否",
            },
          ],
        },
        {
          type: "twodateTime",
          name: "时间",
          value1: "",
          value2: "",
        },
      ],
      btnData: [
        {
          type: "authorityData",
          page: "imageResource",
          label: "保存",
        },
        {
          type: "authorityData",
          page: "imageResource",
          label: "预览",
        },
        {
          type: "authorityData",
          page: "imageResource",
          label: "删除",
        },
      ],
      defaultSort: {
        prop: "time",
        order: "",
      },
    };
  },
  mounted() {
    // 初始化列表
    this.getTableData();
  },
  methods: {
    //查询
    query() {
      this.$request(
        "post",
        "/audit/queryAudit",
        {
          current: this.currentPage,
          size: this.pageSize,
          menu: this.listData[0].value,
          eventName: this.listData[1].value,
          operateUserId: this.listData[2].value,
          eventType: this.listData[3].value.name,
          visitType: this.listData[4].value.name,
          isException: this.listData[5].value.name,
          time1:
            this.listData[6].value1 == ""
              ? ""
              : moment(this.listData[6].value1).format("YYYY-MM-DD HH:mm:ss"),
          time2:
            this.listData[6].value1 == ""
              ? ""
              : moment(this.listData[6].value2).format("YYYY-MM-DD HH:mm:ss"),
        },
        (data) => {
          this.tableData = data.result.tableData;
          this.total = data.result.total;
          if (this.tableData.length > 0) {
            this.rowclick(this.tableData[0]);
          }
        }
      );
    },
    // 设置审计上限
    settingAudit() {
      this.$request("post", "/audit/getSize", { userId: "1" }, (data) => {
        this.currentDialogData.listData[0].value =
          data.result.nowSize == null ? "0MB" : data.result.nowSize;
        this.currentDialogData.listData[1].value =
          data.result.size == null ? "" : data.result.size;
        this.dialogVisible = true;
      });
    },
    // 确定设置审计上限
    sureRelease() {
      if (
        this.currentDialogData.listData[1].value != "" &&
        !this.$formatNum.formatMathDecimal(
          this.currentDialogData.listData[1].value
        )
      ) {
        this.$message.error(
          this.currentDialogData.listData[1].name +
            "格式错误(只能输入数字),请校验后重新输入!"
        );
        return;
      }
      if (this.currentDialogData.listData[1].value.length > 20) {
        this.$message.error(
          this.currentDialogData.listData[1].name +
            "太长(最大只能长度20),请校验后重新输入!"
        );
        return;
      }
      this.$request(
        "post",
        "/audit/setSize",
        { userId: "1", size: this.currentDialogData.listData[1].value },
        (data) => {
          this.$message.success("应用成功");
          this.handleClose();
        }
      );
    },
    // 手动备份
    backAudit() {
      this.$confirm(
        "此操作为手动备份审计日志,此外,每日 0 时,系统将自动进行审计日志的备份!",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$request("post", "/audit/backAudit", { userId: "1" }, (data) => {
            this.$message.success("手动备份成功");
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //获得当前表格数据 并默认选择第一行
    getTableData() {
      this.$request(
        "post",
        "/audit/getAuditList",
        {
          current: this.currentPage,
          size: this.pageSize,
          sortOrder: this.defaultSort.order, // 手动排序规则 ascending descending ""
        },
        (data) => {
          if (data.result.flag == "1" || data.result.flag == 1) {
            this.$message({
              message: "审计日志已超出容量上限的70%!",
              type: "warning",
            });
          }
          if (data.result.flag == "2" || data.result.flag == 2) {
            this.$message({
              message:
                "审计日志已超出容量上限的90%,系统将按时间自动清除最早记录!",
              type: "error",
            });
          }
          if (data.result.auth.flag) {
            this.$notify({
              title: "提示",
              dangerouslyUseHTMLString: true,
              message: data.result.auth.userName + "账号存在越权操作!",
              type: "warning",
            });
          }
          this.tableData = data.result.tableData;
          this.total = data.result.total;
          if (this.tableData.length > 0) {
            this.rowclick(this.tableData[0]);
          }
        }
      );
    },
    // 删除并刷新
    deleteKeyWord(id) {
      this.$request(
        "post",
        "/picResource/delete",
        {
          userId: "1",
          idList: this.multipleSelection,
        },
        (data) => {
          this.currentRow = {};
          this.getTableData();
        }
      );
    },
    handleEdit(type, row) {
      switch (type) {
        case "预览":
          window.open(row.content, "_blank");
          break;

        default:
          break;
      }
    },
    //底部按钮点击事件
    btnClick(item, index) {
      switch (item.label) {
        case "保存":
          this.$request(
            "post",
            "/picResource/update",
            {
              userId: "1",
              id: this.currentRow.id,
              name: this.listData[0].value,
            },
            (data) => {
              this.$message.success("保存成功");
              this.getTableData();
            }
          );
          break;
        case "删除":
          this.$confirm("删除该选中项, 是否继续?", "提示", {
            cancelButtonText: "取消",
            confirmButtonText: "确定",

            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/picResource/delete",
                {
                  userId: "1",
                  idList: [
                    {
                      id: this.currentRow.id,
                    },
                  ],
                },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.getTableData();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;
        case "预览":
          window.open(this.currentRow.content, "_blank");
          break;
        default:
          break;
      }
    },
    //行点击事件
    rowclick(row, colum, event) {
      this.currentRow = row;
      // this.listData[0].value = row.name;
    },
    //表格顶部右上侧按钮点击事件
    tableMenuBtnClick(type) {
      switch (type) {
        //批量删除
        case "delete":
          this.$confirm("删除该选中项, 是否继续?", "提示", {
            cancelButtonText: "取消",
            confirmButtonText: "确定",

            type: "warning",
          })
            .then(() => {
              this.$request(
                "post",
                "/picResource/delete",
                {
                  userId: "1",
                  idList: this.multipleSelection,
                },
                (data) => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.getTableData();
                }
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;
          break;

        default:
          break;
      }
    },
    /**
     * 根据title判断确认添加新站点/栏目/子栏目
     */
    sureAddNew(title) {
      switch (title) {
        case "新建站点关键字":
          this.$request(
            "post",
            "/site/addKeyWord",
            {
              name: this.currentDialogData.listData[0].value,
              id: this.$route.query.id,
              content: this.currentDialogData.listData[1].value,
            },
            (data) => {
              this.dialogVisible = false;
              this.$message.success("新建站点关键字成功!");
              this.$request(
                "post",
                "/site/getKeyWordList",
                {
                  id: this.$route.query.id,
                },
                (data) => {
                  this.tableData = data.result;
                }
              );
            }
          );
          break;
        default:
          break;
      }
    },
    handleClose() {
      this.$base.initDialogData(this.currentDialogData.listData);
      this.dialogVisible = false;
      // this.$confirm("确认关闭？")
      //   .then((_) => {
      //     done();
      //   })
      //   .catch((_) => {});
    },
    //筛选标签
    filterType(value, row) {
      return row.type === value;
    },
    filterStatus(value, row) {
      return row.status === value;
    },
    //选择页的变化
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
      //console.log(`当前页: ${val}`);
    },
    //页容量变化
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
      //console.log(`每页 ${val} 条`);
    },
    //选中行发生变化时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 文件上传成功
     */
    handleAvatarSuccess(response, file, fileList) {
      let requestData = {};
      switch (response.result.title) {
        case "上传文档":
          requestData = {
            file: response.result.path,
            type: this.pathType,
          };
          this.$request("post", "", requestData, (data) => {
            this.$message({
              message: "文档添加成功!",
              type: "success",
            });
            this.getAllAgentList();
          });
          break;
        default:
          break;
      }
    },
    /**
     * 文件上传之前的格式校验
     */ beforeAvatarUpload(file) {
      //console.log("上传文件格式:", file.type);
      const isJPG = file.type === "application/pdf";
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isJPG) {
        this.$message.error("上传文件只能是 PDF 格式!");
      }
      // if (!isLt2M) {
      //   this.$message.error("上传文件大小不能超过 3MB!");
      // }
      return isJPG;
    },
    getCurrentAuthorityData() {
      this.$request(
        "post",
        "/authority/getAuthority",
        {
          id: "1",
          key: ["menuList"],
          relationId: null,
          type: "0",
          name: "运维审计",
        },
        (data) => {
          if (
            data.result.menuList.checkList.length > 0 &&
            data.result.menuList.checkList.indexOf("运维审计") < 0
          ) {
            //console.log("获取首页权限", data.result.menuList);
            this.$message.error("越权操作!");
            setTimeout(() => {
              this.$base.exitSys(this);
              // this.$router.push("/test");
            }, 100);
            return;
          }
          // localStorage.setItem("menuList", JSON.stringify(data.result));
        }
      );
    },
    sortChange(data) {
      this.defaultSort.order = data.order == null ? "" : data.order;
      this.getTableData();
    },
  },
  beforeCreate() {
    localStorage.removeItem("imageResource");
  },
  created() {
    this.getCurrentAuthorityData();
    // let data = {
    //   imageResource: {
    //     name: "图片资源",
    //     checkList: ["保存", "预览", "删除"],
    //   },
    // };
    // localStorage.setItem("imageResource", JSON.stringify(data));
  },
};
</script>

<style lang="less">
#audit {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  > .el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .el-dialog__body {
        height: auto;
        width: 100%;
        > .itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          > .leftArea {
            width: 120px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          > .rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .el-select {
              width: 100%;
            }
            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
            .dw {
              width: 15%;
              margin-left: 10px;
            }
          }
        }
      }
      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }
  > .contentArea {
    width: 100%;
    flex: 1;
    overflow: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    > .optionArea {
      height: auto;
      width: 100%;
      // padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: center;
      background-color: rgba(228, 228, 228, 0.301);
      .el-input__inner {
        width: 200px;
      }
      > .itemOption {
        width: auto;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        > .leftArea {
          width: auto;
          height: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
        > .rightArea {
          // width: 100px;
          flex: 1;
          height: 100%;
          max-width: 300px;
          margin: 5px 10px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .el-select {
            width: 100%;
          }
          img {
            cursor: pointer;
          }
        }
      }
      > .searchArea {
        flex: 1;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .el-table {
      // position: absolute;
      min-width: 1000px;
      overflow: auto;
      .tableBtnArea {
        display: flex;
      }
      .titleArea {
        display: flex;
        // justify-content: center;
        align-items: center;
        position: relative;
        .newArea {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #01cb94;
          position: absolute;
          float: left;
          left: -10px;
          top: 5px;
        }
        .picArea {
          width: 32px;
          height: 32px;
        }
        .videoArea {
          width: 32px;
          height: 32px;
        }
      }
      .operateArea {
        display: flex;
        align-items: center;
        justify-content: space-around;
        > .btnArea {
          display: flex;
        }
        .itemBtn {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: 0.5s;
          margin: 0 3px;
          &:hover {
            color: #01cb94;
          }
        }
        .el-input {
          max-width: 200px;
        }
      }
      .el-table__cell {
        padding: 3px 0;
      }
    }
  }
  > .block {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  > .optionArea {
    height: auto;
    width: 100%;
    padding: 10px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;
    background-color: rgba(228, 228, 228, 0.301);
  }
  > .btnArea {
    width: 100%;
    height: auto;
    padding: 0 10px 10px 0;
    background-color: rgba(228, 228, 228, 0.301);
    display: flex;
    justify-content: space-around;
    align-items: center;
    justify-content: space-around;
    align-items: center;
    .itemBtn {
      width: 80px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #5d7bb5;
      border-radius: 10px;
      transition: 0.5s;

      &:hover {
        cursor: pointer;
        color: #ffffff;
        background-color: #5d7bb5;
        border: 1px solid #ffffff;
      }
    }
  }
}
</style>