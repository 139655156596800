//只针对稿件
let statusData = [{
    status: '',
    authorityList: ['保存', "保存并发布", '预览', '删除', '发布', '驳回', '撤稿', '引用', '刷新', '调整顺序', '置顶', '取消置顶']
}, {
    status: '未发布',
    authorityList: ['保存', "保存并发布", '预览', '删除', '发布', '刷新', '调整顺序', '置顶', '取消置顶']
}, {
    status: '待审核',
    authorityList: ['发布', "保存并发布", '驳回', '预览', '刷新', '调整顺序', '置顶', '取消置顶']
}, {
    status: '已发布',
    authorityList: ["保存", "保存并发布", '撤稿', '定时撤稿', '预览', '引用', '刷新', '调整顺序', '置顶', '取消置顶']
}]

export default {  
    inserted(el, bindings) {     // //console.log("userData", sessionStorage.getItem("userData"));
             // //console.log('指令绑定到元素上', el);
             // //console.log('localstorage', localStorage.getItem("a"));
            
        // //console.log('el', bindings.value);

             //用setTimeOut保证在dom元素创建之后执行,否则会报错
        setTimeout(() => {
            if (!judgeAuthority(bindings.value)) {
                ""
                if (el.parentNode != null)
                    el.parentNode.removeChild(el)
            }
        }, 100);
    },
    update(el, bindings) {
        setTimeout(() => {
            if (!judgeAuthority(bindings.value)) {
                if (el.parentNode != null)
                    el.parentNode.removeChild(el)
            }
        }, 100);
    },
}

//界面数据绑定方式
/* <div>
    <button v-for="(item, index) in btnData" :key="index" v-has="item">
      {{item.label}}
    </button>
  </div> */
//界面按钮数据
// 若界面数据是状态数据，则每次点击单条数据后，对按钮数据的状态进行更新
//以下分别为 权限数据 状态权限数据 公有可见数据
// btnData: [
//     {
//       type: "authorityData",
//       menu: "siteInfo",
//       label: "保存",
//     },
//     {
//       type: "statusData",
//       menu: "siteInfo",
//       status:'已发布',
//       label: "删除",
//     }, 
//     {
//       type: "publicData",
//       menu: "siteInfo",
//       status:'已发布',
//       label: "删除",
//     },
//   ],



//判断逻辑
// 1.先判断是否为权限数据 如若不是权限数据 则直接显示
// 2.如若为权限数据 判断是否为当前用户权限数据
// 3.如为状态数据 再判断当前状态下是否该显示此按钮

function judgeAuthority(bindValue) {
    let currentAuthorityList = JSON.parse(localStorage.getItem(bindValue.page))
        // 1.先判断是否为权限数据 
    if (bindValue.type == 'authorityData') {
        return isUserAuthorityData(bindValue, currentAuthorityList)
    } else if (bindValue.type == 'statusData') {
        return isUserAuthorityData(bindValue, currentAuthorityList) && isStatusData(bindValue, statusData)
    }

}



/**
 * 判断是否显示该用户权限数据
 * @param {*} bindValue 
 * @returns true为显示 false为不显示
 */
function isUserAuthorityData(bindValue, currentAuthorityList) {
    // //console.log("currentAuthorityList:", currentAuthorityList);
    // //console.log("page:", bindValue.page);
    //以page为key进行菜单列表判断
    if (currentAuthorityList[bindValue.page].checkList != null) {
        return currentAuthorityList[bindValue.page].checkList.includes(bindValue.label)
    } else {
        return false
    }
}

/**
 * 判断是否为状态权限数据 并根据状态判断是否显示
 * @param {*} bindValue 
 * @returns true为显示 false为不显示
 */
function isStatusData(bindValue, statusData) {

    for (let index = 0; index < statusData.length; index++) {
        const element = statusData[index];
        // //console.log("bindValuestatus", bindValue.status);
        // //console.log("element", element.status);
        if (bindValue.status === element.status) {
            return element.authorityList.includes(bindValue.label)
        }
    }
    return false

}