<template>
  <div id="authorityAllocate">
    <el-dialog :title="currentDialogData.title" :visible.sync="dialogVisible" width="60%" :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="itemOption" v-for="(item, index) in currentDialogData.listData" :key="index">
        <div class="leftArea">{{ item.name }}:</div>
        <div class="rightArea" v-if="item.type == 'pic'">
          <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" :data="uploadData"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img style="width: 100px; height: 50px" :src="item.value" alt="" />
          </el-upload>
        </div>
        <div class="rightArea" v-if="item.type == 'label'">
          {{ item.value }}
        </div>
        <div class="rightArea" v-if="item.type == 'input'">
          <el-input v-model="item.value" :placeholder="'请输入' + item.name"></el-input>
        </div>
        <div class="rightArea" v-if="item.type == 'select'">
          <el-select v-model="item.value" :placeholder="'请选择' + item.name" filterable>
            <el-option v-for="item2 in item.valueList" :key="item2.id" :label="item2.name" :value="item2.id">
            </el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="sureAddNew(currentDialogData.title)">确 定</el-button>
        <el-button @click="handleClose">取 消</el-button>
      </span>
    </el-dialog>
    <div class="firstLine">
      <el-select v-model="roleSelect" @change="roleChange" filterable placeholder="请选择角色">
        <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
    </div>
    <div class="secondLine">
      <div class="secondLeft">
        <el-select value-key="id" v-model="userSelect" filterable placeholder="请选择用户">
          <el-option v-for="item in userList" :key="item.id" :label="item.name" :value="item">
          </el-option>
        </el-select>
      </div>
      <div class="secondRight">
        <div class="btnArea">
          <div @click="btnClickMethods(item, index)" class="itemBtn" v-for="(item, index) in btnData" :key="index">
            <div v-if="item.btnType == 'ordinary'">{{ item.label }}</div>
            <div v-if="item.btnType == 'checkBox'">
              <el-checkbox v-model="checkAll">{{ item.label }}</el-checkbox>
            </div>
            <div v-if="item.btnType == 'upload'">
              <el-upload class="avatar-uploader" :action="item.url" :show-file-list="false" :data="item.uploadData"
                :on-success="importSuccess" :before-upload="beforeAvatarUpload" accept=".json">
                {{ item.label }}
              </el-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="thirdLine">
      <div class="thirdLeft">当前角色已添加用户：</div>
      <div class="thirdRight">
        <!-- <el-select
          v-model="addUserSelect"
          filterable
          placeholder="添加用户中搜索"
        >
          <el-option
            v-for="item in addUserList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
      </div>
    </div>
    <div class="fourthLine">
      <div class="contentArea">
        <el-table highlight-current-row ref="multipleTable" :data="tableData.filter(
          (data) =>
            !search ||
            data.name.toLowerCase().includes(search.toLowerCase())
        )
          " tooltip-effect="dark" border style="width: 100%; height: 100%; overflow: auto"
          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55"> </el-table-column>

          <el-table-column sortable prop="name" label="用户名">
          </el-table-column>
          <el-table-column width="200">
            <template slot="header" slot-scope="/* eslint-disable vue/no-unused-vars */ scope">
              <div class="operateArea">
                <div class="btnArea">
                  <i @click="tableMenuBtnClick('delete')" class="el-icon-delete itemBtn"></i>
                </div>
                <el-input v-model="search" size="mini" placeholder="用户搜索" />
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="currentPage" :page-sizes="[10, 20, 50, 500]" :page-size="pageSize"
          layout="total,sizes,prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //每页显示多少条
      currentPage: 1,
      pageSize: 10,
      total: 10,
      //当前行
      currentRow: {},
      //选中用户的表格
      tableData: [
        // {
        //   id: "1",
        //   name: "用户1",
        // },
        // {
        //   id: "2",
        //   name: "用户2",
        // },
        // {
        //   id: "3",
        //   name: "用户3",
        // },
      ],
      //对选中用户名搜索
      search: "",
      //弹出窗标志
      dialogVisible: false,
      //currentDialog当前dialog中数据
      roleName: "",
      multipleSelection: [],
      currentDialogData: {
        title: "新建角色",
        listData: [
          {
            type: "input",
            name: "角色名称",
            value: "",
          },
        ],
      },
      //选中角色
      roleSelect: "",
      //角色列表
      roleList: [
        {
          id: "1",
          name: "角色1",
        },
        {
          id: "2",
          name: "角色2",
        },
        {
          id: "3",
          name: "角色3",
        },
      ],
      //选中用户
      userSelect: "",
      //用户列表
      userList: [
        {
          id: "1",
          name: "管理人员",
        },
        {
          id: "2",
          name: "普通人员",
        },
        {
          id: "3",
          name: "审核发布人员",
        },
      ],

      //当前已添加用户列表
      addUserList: [
        {
          id: "1",
          name: "管理人员",
        },
        {
          id: "2",
          name: "普通人员",
        },
        {
          id: "3",
          name: "审核发布人员",
        },
      ],
      //当前已添加用户的选择框
      addUserSelect: "",

      btnData: [
        {
          btnType: "ordinary",
          type: "authorityData",
          menu: "siteInfo",
          label: "添加",
        },
        // {
        //   btnType: "ordinary",
        //   type: "authorityData",
        //   menu: "siteInfo",
        //   label: "新建",
        // },
        // {
        //   btnType: "ordinary",
        //   type: "authorityData",
        //   menu: "siteInfo",
        //   label: "复制",
        // },
        // {
        //   btnType: "ordinary",
        //   type: "authorityData",
        //   menu: "siteInfo",
        //   label: "删除",
        // },
        {
          btnType: "ordinary",
          type: "authorityData",
          menu: "siteInfo",
          label: "保存",
        },
        // {
        //   btnType: "checkBox",
        //   type: "authorityData",
        //   menu: "siteInfo",
        //   label: "所有全选",
        // },
      ],
      //选择的角色
      value: "",
    };
  },
  methods: {
    //角色框选中角色后,获取下方当前角色下的用户
    roleChange(val) {
      this.getUserListByRole();
    },
    //表格右上角按钮事件
    tableMenuBtnClick(type) {
      switch (type) {
        case "delete":
          //console.log("右上角删除");
          if (this.multipleSelection.length == 0) {
            this.$message.error("未选择任何用户");
            return;
          }
          this.$confirm("确认删除该用户么?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.tableData = this.tableData.filter(
                (item) => this.multipleSelection.indexOf(item) == -1
              );
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });

          break;

        default:
          break;
      }
    },
    //选择页的变化
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData();
    },
    //页容量变化
    handleSizeChange(val) {
      this.pageSize = val;
      this.getTableData();
    },
    //选中行发生变化时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //右上按钮点击事件
    btnClickMethods(item, index) {
      switch (item.label) {
        case "添加":
          if (
            this.userSelect == "" ||
            this.userSelect == null ||
            this.roleSelect == "" ||
            this.userSelect == null
          ) {
            this.$message.error("请先选择用户和角色!");
          } else {
            this.tableData.push(this.userSelect);
          }

          break;
        case "新建":
          this.dialogVisible = true;
          this.currentDialogData.title = "新建用户";
          this.currentDialogData.listData = [
            {
              type: "input",
              name: "用户名称",
              value: "",
            },
          ];
          break;
        case "复制":
          this.dialogVisible = true;
          this.currentDialogData.title = "复制用户";
          this.currentDialogData.listData = [
            {
              type: "input",
              name: "用户名称",
              value: "",
            },
          ];
          break;
        case "删除":
          if (this.isUserSelected()) {
            this.$confirm("确认删除该用户么?", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            })
              .then(() => {
                this.$request(
                  "post",
                  "/user/deleteUser",
                  {
                    userId: "1",
                    deleteUserId: this.userSelect,
                  },
                  (data) => {
                    this.$message({
                      type: "success",
                      message: "删除成功!",
                    });
                    this.getUserList();
                  }
                );
              })
              .catch(() => {
                this.$message({
                  type: "info",
                  message: "已取消删除",
                });
              });
          }
          break;
        case "保存":
          if (this.roleSelect == "" || this.userSelect == null) {
            this.$message.error("请先选择角色!");
            return;
          }
          this.$request(
            "post",
            "/user/saveRolesUserList",
            {
              userId: "1",
              roleId: this.roleSelect,
              userList: this.tableData,
            },
            (data) => {
              this.$message({
                type: "success",
                message: "保存成功!",
              });
            }
          );
          break;
        default:
          break;
      }
    },
    sureAddNew(title) {
      switch (title) {
        case "新建用户":
          this.$request(
            "post",
            "/user/addUser",
            {
              userId: "1",
              userName: this.currentDialogData.listData[0].value,
            },
            (data) => {
              this.$message.success("新建用户成功");
              this.getUserList();
              this.dialogVisible = false;
            }
          );
          break;
        case "复制用户":
          if (this.isUserSelected()) {
            this.$request(
              "post",
              "/user/copyUser",
              {
                userId: "1",
                originUserId: this.userSelect,
                newUserName: this.currentDialogData.listData[0].value,
              },
              (data) => {
                this.$message.success("复制用户成功");
                this.getUserList();
                this.dialogVisible = false;
              }
            );
          } else {
            return;
          }
          break;
        default:
          break;
      }
    },
    handleClose(done) {
      done();
    },
    //获得所有用户列表
    getUserList() {
      this.$request(
        "post",
        "/user/getUserListLocal",
        {
          userId: "1",
        },
        (data) => {
          this.userList = data.result;
        }
      );
    },
    //获得当前角色下已选择的用户列表
    getUserListByRole() {
      this.$request(
        "post",
        "/user/getUserListByRoleLocal",
        {
          userId: localStorage.getItem("userId"),
          roleId: this.roleSelect,
        },
        (data) => {
          this.tableData = data.result;
        }
      );
    },
    //获得角色列表
    getRoleList() {
      this.$request(
        "post",
        "/authority/getRoleList",
        {
          userId: "1",
        },
        (data) => {
          this.roleList = data.result;
        }
      );
    },
    //判断是否已经选择角色
    isUserSelected() {
      if (this.userSelect == "") {
        this.$message.error("请先选择用户再进行操作");
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.getUserList();
    this.getRoleList();
  },
};
</script>

<style lang="less">
#authorityAllocate {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;

  >.el-dialog__wrapper {
    .el-dialog {
      .el-dialog__header {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .el-dialog__body {
        height: auto;
        width: 100%;

        >.itemOption {
          width: 100%;
          height: auto;
          display: flex;
          justify-content: center;
          align-items: center;

          >.leftArea {
            width: 180px;
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }

          >.rightArea {
            // width: 100px;
            flex: 1;
            height: 100%;
            margin: 5px 10px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .el-select {
              width: 100%;
            }

            img {
              cursor: pointer;
              width: 100px;
              height: 50px;
            }
          }
        }
      }

      .el-dialog__footer {
        .dialog-footer {
          display: flex;
          justify-content: space-around;
        }
      }
    }
  }

  .el-input__suffix {
    /* 控制箭头位置居中 */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  >.firstLine {
    width: 100%;
    height: auto;

    .el-input__inner {
      width: 300px;
      height: 30px;
    }
  }

  >.secondLine {
    width: 100%;
    height: auto;
    display: flex;
    margin: 5px 0;

    .el-input__inner {
      width: 300px;
      height: 30px;
    }

    .secondLeft {
      flex: 1;
      height: auto;
    }

    .secondRight {
      flex: 1;
      height: auto;

      >.btnArea {
        width: 100%;
        height: auto;
        padding: 0 0;
        // background-color: rgba(228, 228, 228, 0.301);
        display: flex;
        justify-content: space-around;
        align-items: center;
        justify-content: space-around;
        align-items: center;

        .itemBtn {
          width: 120px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #5d7bb5;
          border-radius: 10px;
          transition: 0.5s;

          &:hover {
            cursor: pointer;
            color: #ffffff;
            background-color: #5d7bb5;
            border: 1px solid #ffffff;
          }
        }
      }
    }
  }

  >.thirdLine {
    width: 100%;
    height: auto;
    display: flex;
    margin: 5px 0;

    .el-input__inner {
      width: 300px;
      height: 30px;
    }

    .thirdLeft {
      flex: 1;
      height: auto;
    }

    .thirdRight {
      flex: 1;
      height: auto;
      display: flex;
      justify-content: right;
    }
  }

  .fourthLine {
    flex: 1;
    width: 100%;
    border: 1px solid rgb(205, 205, 205);
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;

    >.contentArea {
      width: 100%;
      flex: 1;
      overflow: auto;
      position: relative;

      .el-table {
        position: absolute;
        min-width: 1000px;
        overflow: auto;

        .tableBtnArea {
          display: flex;
        }

        .titleArea {
          display: flex;
          // justify-content: center;
          align-items: center;
          position: relative;

          .newArea {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #01cb94;
            position: absolute;
            float: left;
            left: -10px;
            top: 5px;
          }

          .picArea {
            width: 32px;
            height: 32px;
          }

          .videoArea {
            width: 32px;
            height: 32px;
          }
        }

        .operateArea {
          display: flex;
          align-items: center;
          justify-content: space-around;

          >.btnArea {
            display: flex;
          }

          .itemBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.5s;
            margin: 0 3px;
            font-size: 1.5em;

            &:hover {
              color: #01cb94;
            }
          }
        }

        .el-table__cell {
          padding: 3px 0;
        }
      }
    }

    >.block {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
