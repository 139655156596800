import Vue from 'vue'
import router from "@/router";

export default function request(method, url, sendData, deal) {
    Vue.axios({
            method: method,
            url: this.$baseurl + url,
            data: JSON.stringify(sendData),
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(
            res => {
                //console.log("请求返回的数据:", res);

                switch (res.data.code) {
                    case 200:
                        deal(res.data)
                        break
                    case 500:
                        // //console.log("500");
                        this.$message.error(res.data.msg);
                        break
                    case 401:
                        this.$message.error(res.data.msg);
                        break
                    case -2:
                        //console.log("-2");
                        // window.open(Vue.prototype.$loginUrl, "_self");
                        router.push('/login')
                        break
                    case -1:
                        // alert("退出================-1")
                        // Vue.axios({
                        //         method: "get",
                        //         url: res.data.loginOutUrl,
                        //         data: { userId: "" },
                        //         headers: {
                        //             'Content-Type': 'application/json'
                        //         }
                        //     }).then(resdd => {
                        //         window.open(Vue.prototype.$loginUrl, "_self");
                        //     }).catch(errordd => {
                        //         //console.log("退出失败");0
                        //         window.open(Vue.prototype.$loginUrl, "_self");
                        //     })
                        window.open(res.data.loginOutUrl, "_target");
                        setTimeout(() => {
                            router.push('/login')
                        }, 1000);
                        break
                    default:
                        this.$message.error("数据异常!");
                        break
                }
            }
        )
        .catch((error) => {

            //console.log("错误信息为:");
            //console.log(error);
            if (error.response.status == 302) {
                window.open(res.headers.Location, "_self");
            }
            if (error.response.status == 409) {
                //console.log("409");
                router.push('/login')
            }
            //状态码不是200的 上上res.data.code是我们设置的状态码 而不是真正的状态码/controller中未捕获的异常/
            this.$message.error('数据异常!');

        })

}